import React, { useState, useEffect } from 'react'
import {
    Card, Breadcrumbs, TextField, Typography, FormControl, Button, IconButton,
    Chip
} from '@mui/material'

import { Link } from 'react-router-dom'
import { Table } from 'reactstrap'
import axios from 'axios';
import Swal from 'sweetalert2';
import { FaTrash } from 'react-icons/fa6';


function Support() {
    const [newSupport, setNewSupport] = useState({ subject: '', message: '' })
    const [supportList, setSupportList] = useState([]);
    const addNew = async () => {
        if (newSupport.subject === '') {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Please enter subject!",
                footer: ''
            });
            return
        }
        if (newSupport.message === '') {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Please enter message!",
                footer: ''
            });
            return
        }
        axios.post(`${process.env.REACT_APP_API_URL}/support/add`, newSupport)
        setNewSupport({ subject: '', message: '' })
        Swal.fire({
            icon: "success",
            title: "Successfully saved",
            showConfirmButton: false,
            timer: 2000
        });
        setTimeout(() => {
            fetchSupport()
        }, 1000);

    }

    const fetchSupport = async () => {
        const resData = await axios.get(`${process.env.REACT_APP_API_URL}/support/list`)
        if (resData.data) {
            setSupportList(resData.data.result)
        }
    }
    const delData = async (data) => {
        await axios.post(`${process.env.REACT_APP_API_URL}/support/delete`, data)
        fetchSupport()
    }
    const deleteRow = async (data) => {
        Swal.fire({
            title: "Are you sure?",
            text: "",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then((result) => {
            if (result.isConfirmed) {
                delData(data)
                setTimeout(() => {
                    Swal.fire({
                        title: "Deleted!",
                        text: "Your file has been deleted.",
                        icon: "success"
                    });
                }, 1000);
            }
        });
    }
    useEffect(() => {
        fetchSupport();
    }, [])
    return (
        <div>
            <div className='box'>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/">
                        Dashboard
                    </Link>
                    <Typography sx={{ color: 'text.primary' }}>Support </Typography>
                </Breadcrumbs>
            </div>
            <div className='text-inner'>
                <Card style={{ padding: '10px' }}>
                    <h4>Create Ticket</h4>
                    <div className="row">
                        <div className='col-sm-12' >
                            <form>
                                <FormControl sx={{ m: 1, }} variant="outlined">
                                    <TextField
                                        label="Subject" size='small'
                                        variant="outlined" type='text'
                                        InputLabelProps={{ shrink: true }}
                                        value={newSupport.subject}
                                        onChange={(e) => { setNewSupport((prev) => ({ ...prev, subject: e.target.value })) }}
                                    />
                                </FormControl>

                                <FormControl sx={{ m: 1, }} variant="outlined">
                                    <TextField
                                        label="Message" size='small'
                                        variant="outlined" type='text'
                                        InputLabelProps={{ shrink: true }}
                                        value={newSupport.message}
                                        onChange={(e) => { setNewSupport((prev) => ({ ...prev, message: e.target.value })) }}
                                    />
                                </FormControl>
                                {/* <FormControl sx={{ m: 1, }} variant="outlined">
                                    <Autocomplete
                                        options={options} size='small'
                                        sx={{ width: 300 }}
                                        renderInput={(params) => <TextField {...params} label="Priority" />}
                                    />
                                </FormControl> */}
                                <FormControl sx={{ m: 1, }} variant="outlined">
                                    <TextField
                                        label="Attachment" size='small'
                                        variant="outlined" type='file'
                                        InputLabelProps={{ shrink: true }}

                                    />
                                </FormControl>
                                <FormControl sx={{ m: 1, }} variant="outlined">
                                    <Button variant="contained" onClick={addNew}>Add</Button>
                                </FormControl>
                            </form>
                        </div>
                    </div>
                </Card>

                <Card style={{ padding: '10px', paddingBottom: '60px' }}>
                    <h4>Support Center </h4>
                    <Table striped>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Date</th>
                                <th>Ticket No</th>
                                <th>Details</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {supportList.length > 0 &&
                                <>
                                    {supportList.map((list, i) => (
                                        <tr key={i}>
                                            <td>{i + 1} </td>
                                            <td>{list.create_date.slice(0,10)} </td>
                                            <td>{list.id} </td>
                                            <td>{list.subject} </td>
                                            <td>
                                                <Chip label="Pending" color="warning" />
                                            </td>
                                            <td>
                                                <IconButton color="error" onClick={() => { deleteRow(list) }}
                                                    aria-label="add to shopping cart">
                                                    <FaTrash />
                                                </IconButton>
                                            </td>
                                        </tr>
                                    ))}
                                </>
                            }
                        </tbody>
                    </Table>
                </Card>
            </div>

        </div >
    )
}

export default Support