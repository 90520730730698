import React,{ useState,useEffect } from 'react'
import { Form, Row, Col, FormGroup, Label, Button } from 'reactstrap'
import ReCAPTCHA from "react-google-recaptcha";
import goldimg from '../img/side_img.jpg';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';

function Regstrion() {

    const [startDate, setStartDate] = useState(new Date());
    const [countryList, setCountryList] = useState([]);
    const [serverUrl] = useState('https://nuggettrap.com/api/');
    const [saveMessage, setSaveMessage] = useState('');
    const [formData, setFormData] = useState({
        title: '',
        f_name: '',
        l_name: '',
        email: '',
        dob: '',
        mobile: '',
        country: '',
        amount: '',
        invest_gold: '',
        mine_claim: '',
        interest_type: '',
        invest_method: '',
        message: '',

    })
    const onChange = (value) => {
        console.log("Captcha value:", value);
    }
    const getAllCountry = async () => {
        const resCuntR = await axios.get('https://crearecoin.com/api/coin/get_all_countrys.php') //setCountryList
        const resCunt = resCuntR.data;
        console.log(resCunt)
        if (!resCunt.error) {
            console.log(resCunt.result)
            setCountryList(resCunt.result)
        }
    }
    const handalSubmit = (e) => {
        e.preventDefault();
        setFormData((prev) => ({ ...prev, dob: startDate }))

        setTimeout(() => {
            fetch(serverUrl + 'welcome.php', {
                method: 'POST',
                headers: {
                    accept: 'application/json'
                },
                body: JSON.stringify(formData)
            })
                .then((res) => res.json())
                .then((result) => {
                    console.log(result);
                    setSaveMessage(result.message)
                    setTimeout(() => {
                        window.scrollTo(0, 1000)
                        setFormData({
                            title: '',
                            f_name: '',
                            l_name: '',
                            email: '',
                            mobile: '',
                            dob: '',
                            country: '',
                            amount: '',
                            invest_gold: '',
                            mine_claim: '',
                            interest_type: '',
                            invest_method: '',
                            message: '',
                        })
                    }, 100);
                })
        }, 500);


    }
    useEffect(() => {
        getAllCountry();
    }, [])
    return (
        <div>
            <div className=''>
                <div className='container'>
                    <div className='row  pt-5'>
                        {/* <div className='col-sm-6'>
                            
                            <p>Gold Token Registration, Securing Your Shine in the Future of Digital Wealth!</p>
                            <img src={goldimg} className='width-100' alt='' />
                        </div> */}
                        <div className='col-sm-12 form-home'>
                            <div>
                                {saveMessage !== '' &&
                                    <p className='message-success'>
                                        Thank you for registering with us, We will get back to you soon.
                                        {/* {saveMessage} */}
                                        {/* Thank you for showing interest. Our Team will get back you soon. */}
                                    </p>
                                }
                            </div>
                            <Form>
                                <Row>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="exampleEmail">Title <span className='text-danger'>*</span></Label>
                                            <select className='form-control ' value={formData.title}
                                                onChange={(e) => { setFormData((prev) => ({ ...prev, title: e.target.value })) }}
                                            >
                                                <option value="">Select</option>
                                                <option value="Mr">Mr.</option>
                                                <option value="Ms">Ms.</option>
                                                <option value="Mrs">Mrs.</option>
                                                <option value="Miss">Miss.</option>
                                                <option value="Dr">Dr.</option>
                                                <option value="Others">Others.</option>
                                            </select>
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="exampleEmail">First name <span className='text-danger'>*</span></Label>
                                            <input type="text" className="form-control "
                                                value={formData.f_name}
                                                onChange={(e) => { setFormData((prev) => ({ ...prev, f_name: e.target.value })) }} />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="exampleEmail">Last name <span className='text-danger'>*</span></Label>
                                            <input type="text" className="form-control" value={formData.l_name}
                                                onChange={(e) => { setFormData((prev) => ({ ...prev, l_name: e.target.value })) }} />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="exampleEmail">Mobile <span className='text-danger'>*</span></Label>
                                            <input type="text" className="form-control" value={formData.mobile}
                                                onChange={(e) => { setFormData((prev) => ({ ...prev, mobile: e.target.value })) }} />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="exampleEmail">Email <span className='text-danger'>*</span></Label>
                                            <input type="text" className="form-control" value={formData.email}
                                                onChange={(e) => { setFormData((prev) => ({ ...prev, email: e.target.value })) }} />
                                        </FormGroup>
                                    </Col>
                                
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label >Date of Birth <span className='text-danger'>*</span></Label>
                                            {/* <DatePicker
                                                dateFormat="yyyy-MM-dd"
                                                selected={startDate}
                                                className="form-control"
                                                onChange={(date) => setStartDate(date)}
                                            /> */}
                                            <input type='date' selected={startDate} 
                                                className="form-control"
                                                onChange={(date) => setStartDate(date)}
                                                />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="exampleEmail">Country <span className='text-danger'>*</span></Label>
                                            <select className='form-control' value={formData.country}
                                                onChange={(e) => { setFormData((prev) => ({ ...prev, country: e.target.value })) }}>
                                                <option value=''>Select option</option>
                                                {countryList?.length > 0 &&
                                                    <>
                                                        {countryList.map((list, i) => (
                                                            <option value={list.name} key={i}>{list.name}</option>
                                                        ))}
                                                    </>
                                                }
                                            </select>
                                        </FormGroup>
                                    </Col>

                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="exampleEmail">Have you ever Invested in a Placer Mine Claim Before? <span className='text-danger'>*</span></Label>
                                            <div className="radio">
                                                <label><input type="radio" value="yes" name='mine_claim'
                                                    onChange={(e) => { setFormData((prev) => ({ ...prev, mine_claim: e.target.value })) }}
                                                />&nbsp; Yes </label>
                                            </div>
                                            <div className="radio">
                                                <label><input type="radio" value="no" name='mine_claim'
                                                    onChange={(e) => { setFormData((prev) => ({ ...prev, mine_claim: e.target.value })) }} />
                                                    &nbsp; No </label>
                                            </div>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="exampleEmail">Amount $<span className='text-danger'>*</span> </Label>
                                            <input type="text" className="form-control" value={formData.amount}
                                                onChange={(e) => { setFormData((prev) => ({ ...prev, amount: e.target.value })) }} />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="exampleEmail">Investment Interest Type
                                                <span className='text-danger'>*</span></Label>
                                            <select className='form-control' name="interest_type"
                                                value={formData.interest_type}
                                                onChange={(e) => { setFormData((prev) => ({ ...prev, interest_type: e.target.value })) }}>
                                                <option value="">Select option</option>
                                                <option value="Non-Accredited_Investor">Non-Accredited Investor</option>
                                                <option value="Accredited_Investor">Accredited Investor</option>
                                                <option value="Institutional_Broker">Institutional Broker</option>
                                                <option value="Family_Office">Family Office</option>
                                            </select>
                                        </FormGroup>
                                    </Col>

                                    <Col md={12}>
                                        <FormGroup>
                                            <Label for="exampleEmail">Investment Method Preference
                                                <span className='text-danger'>*</span>
                                            </Label>
                                            <select className='form-control' name="invest_method"
                                                value={formData.invest_method}
                                                onChange={(e) => { setFormData((prev) => ({ ...prev, invest_method: e.target.value })) }}
                                            >
                                                <option value="">Select option</option>
                                                <option value="Fiat">Fiat</option>
                                                <option value="Accredited_Investor">BTC</option>
                                                <option value="Institutional_Broker">ETH</option>
                                                <option value="Family_Office">Other</option>
                                            </select>
                                        </FormGroup>
                                    </Col>

                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label for="exampleEmail">Have you ever invested in a Digital Asset Token ?
                                                <span className='text-danger'>*</span>
                                            </Label>
                                            <div className="radio">
                                                <label><input type="radio" value="yes" name='gold_invest'
                                                    onChange={(e) => { setFormData((prev) => ({ ...prev, invest_gold: e.target.value })) }}
                                                />&nbsp; Yes </label>
                                            </div>
                                            <div className="radio">
                                                <label><input type="radio" value="no" name='gold_invest'
                                                    onChange={(e) => { setFormData((prev) => ({ ...prev, invest_gold: e.target.value })) }} />
                                                    &nbsp; No </label>
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label for="exampleEmail">Message
                                                <span className='text-danger'>*</span>
                                            </Label>
                                            <textarea className='form-control' name="message"
                                                value={formData.message}
                                                onChange={(e) => { setFormData((prev) => ({ ...prev, message: e.target.value })) }}></textarea>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12} className='pt-3'>
                                        <p className='disclmr'><b>Disclaimer</b> : We are solely interested in evaluating the Countries of interest from our Pre-registered
                                            subscribers to best valuate which jurisdictions may be of interest to possibly extend our registration
                                            for the offering.</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12} className='pt-3'>
                                        <div className='mob-mer-left'>
                                            <ReCAPTCHA
                                                sitekey="6LcHwQUpAAAAAG_RZeMJs-oON-mGHGp_2bplJN-O"
                                                onChange={onChange}
                                                theme="dark"
                                            />
                                        </div>
                                    </Col>
                                    <Col md={12} className='pt-3 pb-5'>
                                        <Button colors="primary" className='fw-bold' block onClick={handalSubmit}>Submit</Button>
                                    </Col>
                                </Row>

                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Regstrion