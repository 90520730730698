import React, { useState, useEffect } from 'react'
import {
    Card, Breadcrumbs, Typography
} from '@mui/material'
import { Link } from 'react-router-dom'
import { Form, FormGroup, Label, Col, Input, Button } from 'reactstrap'
import { PiUserCircleGearLight } from "react-icons/pi";
import axios from 'axios';
import Swal from 'sweetalert2';

function MarketMaker() {
    const [newMarketMarker, setNewMM] = useState({
        name: '', contact_persone: '', email: '', contact: '',
        wallte_address: '', contract_link: '', contract_file: ''
    })
    const saveMM = async () => {
        if (newMarketMarker.name === '' || newMarketMarker.email === "") {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Please enter all details!",
                footer: ''
            });
            return
        }
        await axios.post(`${process.env.REACT_APP_API_URL}/marketmarker/add`, newMarketMarker)
        setNewMM({
            name: '', contact_persone: '', email: '', contact: '',
            wallte_address: '', contract_link: '', contract_file: ''
        })
        Swal.fire({
            icon: "success",
            title: "Successfully saved",
            showConfirmButton: false,
            timer: 2000
        });

    }


    return (
        <div>
            <div className='box'>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/">
                        Dashboard
                    </Link>
                    <Typography sx={{ color: 'text.primary' }}>MARKET MAKER MANAGEMENT </Typography>
                </Breadcrumbs>
            </div>
            <div className='text-inner'>
                <Card style={{ padding: '10px', paddingBottom: '60px' }}>
                    <h4>MARKET MAKER MANAGEMENT</h4>
                    <div>
                        {/* {JSON.stringify(user)} */}

                    </div>
                    <hr />
                    <Form className='admin'>
                        <FormGroup row>
                            <Label sm={2}> Name of the Market Maker </Label>
                            <Col sm={4}>
                                <Input
                                    type="text" value={newMarketMarker.name}
                                    onChange={(e) => { setNewMM((prev) => ({ ...prev, name: e.target.value })) }}
                                />
                            </Col>
                            <Label sm={2}> Concern Person </Label>
                            <Col sm={4}>
                                <Input
                                    type="text" value={newMarketMarker.concern_persone}
                                    onChange={(e) => { setNewMM((prev) => ({ ...prev, concern_persone: e.target.value })) }}
                                />
                            </Col>
                        </FormGroup>

                        <FormGroup row>
                            <Label sm={2}>Email</Label>
                            <Col sm={4}>
                                <Input
                                    type="email" value={newMarketMarker.email}
                                    onChange={(e) => { setNewMM((prev) => ({ ...prev, email: e.target.value })) }}
                                />
                            </Col>

                            <Label sm={2}>Contact info</Label>
                            <Col sm={4}>
                                <Input
                                    type="text" value={newMarketMarker.contact}
                                    onChange={(e) => { setNewMM((prev) => ({ ...prev, contact: e.target.value })) }}
                                />
                            </Col>
                        </FormGroup>
                        <h4>Wallet Addresses:</h4>
                        <hr />
                        <FormGroup row>
                            <Label sm={3}> Wallet Address </Label>
                            <Col sm={9}>
                                <Input
                                    type="text" value={newMarketMarker.wallte_address}
                                    onChange={(e) => { setNewMM((prev) => ({ ...prev, wallte_address: e.target.value })) }}
                                />
                            </Col>
                        </FormGroup>

                        <br />
                        <h4>Contract Upload</h4>
                        <hr />
                        <FormGroup row>
                            <Label sm={3}> File Attachment</Label>
                            <Col sm={9}>
                                <Input
                                    type="file"
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label sm={3}>Or/ Link</Label>
                            <Col sm={9}>
                                <Input
                                    type="text" value={newMarketMarker.contract_link}
                                    onChange={(e) => { setNewMM((prev) => ({ ...prev, contract_link: e.target.value })) }}
                                />
                            </Col>
                        </FormGroup>
                        <br />
                        <Button type='button' color='success' block onClick={saveMM}>Add</Button>
                    </Form>
                </Card>
            </div>
        </div>
    )
}

export default MarketMaker