import React, { useEffect, useState } from 'react'

import { Button, Table } from 'reactstrap';
import { Card, TextField, Breadcrumbs, Typography, FormControl, Autocomplete, IconButton, Box } from '@mui/material'

import Editor from 'react-simple-wysiwyg';
import axios from 'axios';
import Swal from 'sweetalert2';
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from 'react-router-dom';
import { FaEdit } from 'react-icons/fa';
import { FaTrash } from 'react-icons/fa6';

function AddExchanges() {
    const [newsData, setNewsData] = useState('');
    const [exchangeList, setExchangeLIst] = useState([]);

    const onChange = () => {
        console.log()
    }
    const deleteData = async(row)=>{
        await axios.post(`${process.env.REACT_APP_API_URL}/exchange/delete`, row)
        setTimeout(() => {
            Swal.fire({
                title: "Delete success!",
                icon: "success",
                draggable: true
            });
            setNewsData('')
            fetchData();
        }, 1000);
    }
    const saveNews = async () => {
        await axios.post(`${process.env.REACT_APP_API_URL}/exchange/add`, {name: newsData})
        setTimeout(() => {
            Swal.fire({
                title: "Insert success!",
                icon: "success",
                draggable: true
            });
            setNewsData('')
            fetchData();
        }, 1000);
    }
    const fetchData = async () => {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/exchange/list`)
        setExchangeLIst(res.data.result)
    }
    useEffect(() => {
        fetchData();
    }, [])
    return (
        <div>
            <div className='box'>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/">
                        Dashboard
                    </Link>
                    <Typography sx={{ color: 'text.primary' }}>Exchange </Typography>
                </Breadcrumbs>
            </div>
            <div className='text-inner'>
                <Card style={{ padding: '10px' }}>
                    <h4>Add Exchange</h4>
                    <div className="row">
                        <div className='col-sm-12'>
                            <Box>
                                <div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-10'>
                                        <TextField id="outlined-basic" label="Name" variant="outlined"
                                            sx={{ '& > :not(style)': { m: 1, width: '90ch' } }}
                                            onChange={(e) => { setNewsData(e.target.value) }}
                                            value={newsData} />

                                        <div>

                                        </div>
                                        <br />
                                        <Button color='success' block onClick={saveNews}>Save</Button>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-12'>
                                        <Table className='table '>
                                            <thead>
                                                <tr>
                                                    <td>Sl</td>
                                                    <td>Name</td>
                                                    <td>Action</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {exchangeList.length > 0 &&
                                                    <>
                                                        {exchangeList.map((list, i) => (
                                                            <tr key={i}>
                                                                <td>{i + 1} </td>
                                                                <td>{list.name} </td>
                                                                <td>
                                                                    {/* <IconButton aria-label="delete"  color="warning"
                                                                    onClick={()=>{ deleteData(list) }}>
                                                                        <FaEdit />
                                                                    </IconButton> */}
                                                                    <IconButton aria-label="delete"  color="error"
                                                                    onClick={()=>{ deleteData(list) }}>
                                                                        <FaTrash />
                                                                    </IconButton>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </>
                                                }
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </Box>
                        </div>
                    </div>
                </Card>
            </div>


        </div>
    )
}
export default AddExchanges