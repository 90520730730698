//import './App.css';
import './style.css';
import { Route, Routes } from 'react-router-dom';
import Home from './component/Home';
import Login from './component/Login';
import Dashboard from './admin/Dashboard';
import LoginAd from './component/LoginAd';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { selectedGridRowsCountSelector } from '@mui/x-data-grid';

function App() {
  const [languageList, setLanList] = useState([]);
  const fetchLang = async () => {
    const resR = await axios.get(`${process.env.REACT_APP_API_URL}/language/list`);
    //setLanList(resR.data.result);
    localStorage.setItem('language', JSON.stringify(resR.data.result));
  }

  useEffect(() => {
    fetchLang();
    console.log('main app.js');
  }, []);

  return (
    <div>
      <Routes>
        <Route path='/' element={<Home />}></Route>
        <Route path='/home/*' element={<Home />}></Route>
        <Route path='/login' element={<Login />}></Route>
        <Route path='/admin-login' element={<LoginAd />}></Route>
        <Route path='/dashboard/*' element={<Dashboard />}></Route>
      </Routes>
    </div>
  );
}
export default App;