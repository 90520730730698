import React, { useState, useEffect } from 'react'
import {
    Card, Breadcrumbs, Typography,
    IconButton
} from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'
import { Form, FormGroup, Label, Col, Input, Button, Table } from 'reactstrap'
import { AiOutlineFileAdd } from 'react-icons/ai'
import axios from 'axios'

function LiquidityManagementList() {

    const navigate = useNavigate();
    const [list, setList] = useState([]);

    const fetchList = async () => {
        const resRelt = await axios.get(`${process.env.REACT_APP_API_URL}/liquidity-management/list`)
        setList(resRelt.data.result)
    }
    const arrToString = (arr) => {
        var str = "";
        for (let i = 0; i < arr.length; i++) {
            str += arr[i].value;
            str += ',';
            console.log(str)
        }
        setTimeout(() => {
            return str;
        }, 1000);
    }
    useEffect(() => {
        fetchList();
    }, [])

    return (
        <div>
            <div className='box'>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/">
                        Dashboard
                    </Link>
                    <Typography sx={{ color: 'text.primary' }}>List</Typography>
                </Breadcrumbs>
            </div>
            <div className='text-inner'>
                <Button variant="contained" color='primary'
                    onClick={() => { navigate('/dashboard/liquidity-management') }}
                >Add new
                    <AiOutlineFileAdd size={20} />
                </Button>
                <Card style={{ padding: '10px' }}>
                    <h4>Liquidity Management list</h4>
                    <div className='table-responsive'>
                        <Table>
                            <thead>
                                <tr>
                                    <th>Sl</th>
                                    <th>Exchange Name</th>
                                    <th>Current balance</th>
                                    <th>Liquidity Deposit</th>
                                    <th>Withdrawal</th>
                                </tr>
                            </thead>
                            <tbody>
                                {list.length > 0 &&
                                    <>
                                        {list.map((list, i) => (
                                            <tr key={i}>
                                                <td>{i + 1} </td>
                                                <td>{list.exchange_name} </td>
                                                <td>{list.current_balance} </td>
                                                <td>
                                                    {list.liquidity_deposit.map((item, index) => (
                                                        <div key={index}>
                                                            Exchang Name : {item.exchange_name.value} <br />
                                                            Deposit Amount : {item.deposit_amount} <br />
                                                            Deposit Date : {item.deposit_date} <br />
                                                            Deposit By : {item.deposit_by} <br />
                                                            transaction hash : {item.transaction_hash} <br />
                                                            Remark : {item.remark} <br />
                                                        </div>
                                                    ))}
                                                </td>
                                                <td>
                                                    {list.withdrawal.map((item, index) => (
                                                        <div key={index}>
                                                            Exchang Name : {item.exchange_name.value} <br />
                                                            Amount : {item.amount} <br />
                                                            Withdrawal Date : {item.withdrawal_date} <br />
                                                            Reason : {item.reason_for_withdrawal} <br />
                                                            
                                                        </div>
                                                    ))}
                                                </td>

                                            </tr>
                                        ))}
                                    </>
                                }

                            </tbody>
                        </Table>
                    </div>
                </Card>
            </div>
        </div>
    )
}
export default LiquidityManagementList