import React from 'react'

function Location() {
    return (
        <div>
            <div className='container header-offset'>
                <div className='pt-5'>
                    <h2>Assay Report</h2>
                    <div>
                        <iframe src='https://nuggettrap.com/uploads/MSALabsReport.pdf' width='100%' height='500px' title='asay report'></iframe>
                    </div>
                {/* <h2>Location</h2>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d17144.749700800763!2d-122.83542024033999!3d53.9686088156825!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x53889736534f66a1%3A0x86d6607f6c26c06a!2sPidherny%20Recreation%20Site!5e1!3m2!1sen!2sin!4v1731425006225!5m2!1sen!2sin" width="100%" height="450"
                        allowfullscreen="" title="location" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}

                </div>
            </div>
        </div>
    )
}

export default Location