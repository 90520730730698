import React, { useState, useEffect } from 'react'
import {
    Card, Breadcrumbs, Typography,
    IconButton
} from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'
import { Form, FormGroup, Label, Col, Input, Button, Table } from 'reactstrap'
import { AiOutlineFileAdd } from 'react-icons/ai'
import axios from 'axios'

function MarketMarkerList() {
    const navigate = useNavigate();
    const [list, setList] = useState([]);

    const fetchList = async () => {
        const resRelt = await axios.get(`${process.env.REACT_APP_API_URL}/marketmarker/list`)
        setList(resRelt.data.result)

    }
    useEffect(() => {
        fetchList();
    }, [])

    return (
        <div>
            <div className='box'>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/">
                        Dashboard
                    </Link>
                    <Typography sx={{ color: 'text.primary' }}>List</Typography>
                </Breadcrumbs>
            </div>
            <div className='text-inner'>
                <Button variant="contained" color='primary'
                    onClick={() => { navigate('/dashboard/market-marker') }}
                >Add new
                    <AiOutlineFileAdd size={20} />
                </Button>
                <Card style={{ padding: '10px' }}>
                    <h4>Market Marker list</h4>
                    <div className='table-responsive'>
                        <Table>
                            <thead>
                                <tr>
                                    <th>Sl</th>
                                    <th>Name</th>
                                    <th>Contact person</th>
                                    <th>Email</th>
                                    <th>Contact</th>
                                    <th>Wallet Address</th>
                                </tr>
                            </thead>
                            <tbody>
                                {list.length > 0 &&
                                    <>
                                        {list.map((list, i) => (
                                            <tr key={i}>
                                                <td>{i+1} </td>
                                                <td>{list.name} </td>
                                                <td>{list.contact_person} </td>
                                                <td>{list.email} </td>
                                                <td>{list.contact} </td>
                                                <td>{list.wallet_address} </td>
                                            </tr>
                                        ))}
                                    </>
                                }

                            </tbody>
                        </Table>
                    </div>
                </Card>
            </div>
        </div>
    )
}

export default MarketMarkerList