import React, { useEffect, useState } from "react";
import logo from "../img/logo.png";
import { Link, useNavigate } from "react-router-dom";

import imga11 from "../img/img11.png";
import img12 from "../img/img12.png";
// import img13 from "../img/img13.png";
// import img14 from "../img/img14.png";
import bitmart from "../img/Bitmart Logo 4 White.png";
import lbank from "../img/Lbank Logo 2.png";

import CookieConsent from "react-cookie-consent";
import { Button } from "reactstrap";
import imgOne from "../img/img1.jpeg";
import imgTwo from "../img/img2.jpeg";
import imgThree from "../img/img3.jpeg";
import TokenInfo from "./TokenInfo";
import videolink from "../img/video.mp4";
import { getLanguage } from "../utils";

function Main() {
    const [seeMore, setSeeMore] = useState(false);
    const navigate = useNavigate();
    const [bal, setBal] = useState(0);
    const [goldPrice, setGoldPrice] = useState(0);

    const getBal = (data) => {
        console.log(data)
        //alert(data);
    };
    useEffect(() => {
        if (localStorage.getItem("blanace")) {
            setBal(localStorage.getItem("blanace"));
        }
        setInterval(() => {
            if (localStorage.getItem("blanace")) {
                setBal(localStorage.getItem("blanace"));
            }
            //console.log(localStorage.getItem("blanace"));
        }, 1000);
    }, []);
    useEffect(() => {
        fetch('https://data-asg.goldprice.org/dbXRates/CAD', {
            method: 'GET',
            headers: {
                accept: 'application/json'
            }
        }
        )
            .then((res) => res.json())
            .then((res) => {
                if (res.error) {
                    alert(res.error)
                } else {
                    console.log(res.items[0].xauPrice)
                    setGoldPrice(res.items[0].xauPrice.toFixed(2))
                }
                console.log(res)
                //setGoldPrice(res.price_gram_22k.toFixed(2))
            })

    })

    useEffect(() => {
        window.addEventListener("scroll", function() {
            const stickyDiv = document.querySelector(".fullscreen");
            const fadeInLeft = document.querySelector(".fade-in-from-left");
            const fadeInRight = document.querySelector(".fade-in-from-right");
            if(window.innerWidth >= 1000 && stickyDiv) {
                const rect = stickyDiv.getBoundingClientRect();
                const stickyDivTop = rect.top;
                const stickyDivHeight = stickyDiv.offsetHeight;

                if (stickyDivTop <= window.innerHeight && stickyDivTop + stickyDivHeight >= 0) {
                    let factor = window.innerWidth <= 800 ? 1 : 3;
                    let scrollFraction = Math.min(1, (window.innerHeight - stickyDivTop) / stickyDivHeight) * factor;
                    fadeInLeft.style.opacity = scrollFraction;
                    fadeInLeft.style.transform = `translateX(${(1 - scrollFraction) * -40}vw)`;
                    fadeInRight.style.opacity = scrollFraction;
                    fadeInRight.style.transform = `translateX(${(1 - scrollFraction) * 40}vw)`;
                } else {
                    fadeInRight.style.opacity = 0;
                    fadeInRight.style.transform = "translateX(-40vw)";
                    fadeInRight.style.opacity = 0;
                    fadeInRight.style.transform = "translateX(40vw)";
                }
            }
            else if(stickyDiv) {
                stickyDiv.style.height = "100vh";
                stickyDiv.style.position = "relative";
                fadeInLeft.style.fontSize = "6vh";
                fadeInLeft.style.opacity = 1;
                fadeInLeft.style.transform = "translateX(0)";
                fadeInRight.style.fontSize = "1.5vh";
                fadeInRight.style.height = "50vh"
                fadeInRight.style.opacity = 1;
                fadeInRight.style.transform = "translateX(0)";
            }
        });
    }, []);

    return (
        <div className="bg-color-grey-darkest">
            <CookieConsent
                overlay={false}
                location="bottom"
                buttonText="I Understand"
                cookieName="myAwesomeCookieName2"
                style={{ background: "#111" }}
                contentStyle={{ margin: "15px", width: "calc(100% - 30px)" }}
                buttonStyle={{ backgroundColor: "var(--color-primary)", color: "white", fontSize: "13px" }}
                expires={150}
            >
                <div id="privacy-policy">
                    <p>Our Privacy Policy defines how we collect, use,
                        and disclose personal information when you visit, browse, or use our website. By accessing or using the Website, you agree to the terms of this Policy. If you do not agree with any part of this Policy,
                        you must not use the Website.</p>
                    {seeMore === false &&
                        <>
                            <span className="text-link" onClick={(e) => {
                                setSeeMore(!seeMore);
                            }}>See more...</span>
                        </>
                    }
                    {seeMore === true &&
                        <>
                            <ul>
                                <li>1. Information We Collect and use for Analytics</li>
                                <li>1.1 Personal Information:
                                    We may collect personal information that you voluntarily provide to us when you
                                    interact with the Website. This may include your name, email address, phone number,
                                    and any other information you choose to provide.
                                </li>
                                <li>
                                    1.2 Usage Information:
                                    We may automatically collect certain information when you visit the Website,
                                    such as your IP address, browser type, operating system, referring website, pages viewed,
                                    and the dates and times of your visits.
                                    This information is collected using cookies, web beacons, and similar technologies.
                                </li>
                                <li>2. Use of Information</li>
                                <li>
                                    2.1 We may use the information we collect to:
                                    <br />
                                    – Provide, maintain, and improve the Website’s functionality.
                                    <br />
                                    – Respond to your inquiries and communicate with you about our services.
                                    <br />
                                    – Send you promotional materials or newsletters, subject to your consent where required by law.
                                    <br />
                                    – Personalize and enhance your experience on the Website.
                                    <br />
                                    – Monitor and analyze usage and trends to improve the Website and our services.
                                    <br />
                                    – Protect the security and integrity of the Website and our users.
                                </li>
                                <li>3. Disclosure of Information</li>
                                <li>
                                    3.1 We may share your personal information with:
                                    <br />
                                    – Service providers who assist us in operating the Website and providing our services.
                                    <br />
                                    – Third-party analytics providers to analyze usage and trends.
                                    <br />
                                    – Law enforcement authorities, government officials, or other third parties when required by law or in response to a legal process.
                                    <br />
                                    – Other third parties with your consent or at your direction.
                                </li>
                                <li>4. Data Security</li>
                                <li>
                                    4.1 We take reasonable measures to protect the security of your personal information. However, no method of transmission over
                                    the internet or electronic storage is completely secure, and we cannot guarantee absolute security.
                                </li>
                                <li>5. Third-Party Links</li>
                                <li>
                                    5.1 The Website may contain links to third-party websites. We are not responsible for the privacy practices or the content of such websites.
                                    We encourage you to read the privacy policies of those websites before providing any personal information.
                                </li>
                                <li>6. Children’s Privacy</li>
                                <li>
                                    6.1 The Website is not intended for use by individuals under the age of 18. We do not knowingly collect personal information from children. If we become aware that we have inadvertently
                                    collected personal information from a child, we will take steps to delete it as soon as possible.
                                </li>
                                <li>7. Your Rights</li>
                                <li>
                                    7.1 You may have certain rights regarding your personal information, including the right to access, correct, or delete the information we hold about you.
                                    To exercise your rights, please contact us using the information provided at the end of this Policy.
                                </li>
                                <li>8. Changes to the Policy</li>
                                <li>
                                    8.1 We may update this Policy from time to time. Any changes will be effective immediately upon posting on the Website. Your continued use
                                    of the Website after any modifications to the Policy constitutes your acceptance of the revised terms.
                                </li>
                                <li>9. Contact Us</li>
                                <li>
                                    9.1 If you have any questions, concerns, or requests regarding this Policy or the privacy practices of the Website, please contact us at support@cunninghammining.com or support@nuggettrap.com
                                    By using the Website, you acknowledge that you have read, understood, and agreed to be bound by this Privacy Policy. If you do not agree to this Policy, please refrain from using the Website.
                                </li>
                            </ul>
                            {seeMore === true &&
                                <>
                                    <span className="text-link" onClick={(e) => {
                                        setSeeMore(!seeMore);
                                    }}>See less...</span>
                                </>
                            }
                        </>
                    }
                </div>
            </CookieConsent>
            <div className="video-container">
                <video
                    className="video-background"
                    autoPlay
                    loop
                    muted
                    playsInline
                >
                    <source src={videolink} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                <div className="title-logo">
                    <img src={logo} alt="" />
                    {/* Nugget Trap Gold RWA & Utility Token */}
                    <p className="title-label">
                        RWA & Utility Token
                    </p>
                    <p className="title-label-sm">
                        <span>Gold Price</span> {goldPrice} CAD
                    </p>
                    <p className="title-label-sm">
                        <Link to="https://etherscan.io/token/0x810296898a4f870619b015c0b13c8c6e65b305e3#code"><span>NGTG$$ / NGTG</span></Link>
                    </p>
                </div>
            </div>
            <div className="bg-color-black">
                <div className="container">
                    <h4 className="heading">
                        {getLanguage("FULLING_MINING_TEXT")}
                    </h4>
                    <h3 className="heading-signature">
                        <span className="font-color-grey-dark">-</span> Nugget Trap Team
                    </h3>
                </div>
            </div>
            <div className="bg-color-grey-darkest pt-5 pb-5 fullscreen">
                <h1 className="fade-in-from-left">
                    Real World <span className="font-color-primary">Asset</span>.
                </h1>
                <p className="fade-in-from-right">
                    <span>"The Paper Markets are Inefficient and Broken - Welcome to Digital Blockchain Tokenization Solution - A True RWA Utility Token for the Mining Industry."</span>
                    <hr className="horizontal-rule" />
                    {getLanguage("HOME_PAGE_BANNER_TEXT_1")}
                    <br /><br />
                    {getLanguage("HOME_PAGE_BANNER_TEXT_2")}
                </p>
            </div>
            <div className="bg-color-grey-darkest container">
                <div className="row col-tex img-box">
                    <div className="col-lg-4 img-cointer">
                        <div class="col-header pt-5 pb-5">
                            Digitization
                        </div>
                        <img src={imgOne} alt="" />
                    </div>
                    <div className="col-lg-4 img-cointer">
                        <div class="col-header pt-5 pb-5">
                            Tokenization
                        </div>
                        <img src={imgTwo} alt="" />
                    </div>
                    <div className="col-lg-4 img-cointer">
                        <div class="col-header pt-5 pb-5">
                            Real World Assets
                        </div>
                        <img src={imgThree} alt="" />
                    </div>
                </div>
            </div>
            <div className="container col-lg-12 home-paragraph">
                <p>{getLanguage("HOME_PARA_1")}</p>
            </div>
            <div className="pt-5 pb-5 cex-bg">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-6 pt-5 cex-link">
                            <h2 className="font-color-grey">The Nugget Trap Ecosystem</h2>
                            <h5 className="font-color-grey-dark mb-4">Available now on major exchanges</h5>
                            <div>
                                <Button color="primary" onClick={() => { navigate("/home/cex") }}>View More</Button>
                            </div>
                        </div>
                        <div className="col-sm-6 cex-images">
                            <div className="row pt-5 pb-5">
                                <div className="col-sm-6">
                                    <img src={bitmart} alt="" />
                                </div>
                                <div className="col-sm-6">
                                    <img src={lbank} alt="" />
                                </div>
                                <div className="col-sm-6">
                                    <img src={imga11} alt="" />
                                </div>
                                <div className="col-sm-6">
                                    <img src={img12} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Main