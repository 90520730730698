import React, { useState, useEffect } from 'react'
import {
  Card, Breadcrumbs, Typography, IconButton,
} from '@mui/material'
import { Link } from 'react-router-dom'
import { FaEthereum, FaTrash } from "react-icons/fa6";
import { Input, InputGroup, Button } from 'reactstrap';
import axios from 'axios';
import Swal from 'sweetalert2';

function WalletAddress() {
  const [newAddress, setNewAddress] = useState('');
  const [userData, setUser] = useState('');
  const [addressList, setAddresList] = useState([])

  const addAddress = async () => {
    if (newAddress === '') {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please enter address!",
        footer: ''
      });
      return 
    }
    if (newAddress !== '') {
      await axios.post(`${process.env.REACT_APP_API_URL}/wallet/add`, { user_id: userData.id, address: newAddress })
      Swal.fire({
        icon: "success",
        title: "Successfully saved",
        showConfirmButton: false,
        timer: 2000
      });
      setTimeout(() => {
        fetchList()
      }, 1000);
      setNewAddress('')
      fetchList()
    }
  }
  const fetchList = async () => {
    const resData = await axios.get(`${process.env.REACT_APP_API_URL}/wallet/list`)
    if (resData.data) {
      setAddresList(resData.data.result)
    }
  }
  const delData = async (data) => {
    await axios.post(`${process.env.REACT_APP_API_URL}/wallet/delete`, data)
    fetchList()
}
const deleteRow = async (data) => {
    Swal.fire({
        title: "Are you sure?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
    }).then((result) => {
        if (result.isConfirmed) {
            delData(data)
            setTimeout(() => {
                Swal.fire({
                    title: "Deleted!",
                    text: "Your file has been deleted.",
                    icon: "success"
                });
            }, 1000);
        }
    });
}

  useEffect(() => {
    if (localStorage.getItem('user')) {
      const data = JSON.parse(localStorage.getItem('user'))
      setUser(data)
    }
    fetchList()
  }, [])
  return (
    <div>
      <div className='box'>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="/">
            Dashboard
          </Link>
          <Typography sx={{ color: 'text.primary' }}>Wallet Addresses</Typography>
        </Breadcrumbs>
      </div>
      <div className='text-inner'>

        <Card style={{ padding: '10px', paddingBottom: '60px' }}>
          <h4>Primary Addresses</h4>
          {addressList.length > 0 &&
            <>
              {addressList.map((list, i) => (
                <div className='row'>
                  <div className='col-sm-10'>
                    <div className='wl-address'>
                      <p>{list.address} </p>
                    </div>
                  </div>
                  <div className='col-sm-1'>
                    <h5>Balance</h5>
                    <div>
                      $0.00 USD
                    </div>
                    <div>
                      <FaEthereum />
                      0
                    </div>
                  </div>
                  <div className='col-sm-1'>
                    <IconButton color="error" onClick={() => { deleteRow(list) }}
                      aria-label="add to shopping cart">
                      <FaTrash />
                    </IconButton>
                  </div>
                </div>
              ))
              }
            </>
          }

          <div className='row'>
            <div className='col-sm-12'>
              <h5>Add New Address</h5>
              <InputGroup>
                <Input
                  value={newAddress}
                  onChange={(e) => { setNewAddress(e.target.value) }} />
                <Button color="dark" onClick={addAddress}
                >
                  Add Address
                </Button>
              </InputGroup>
            </div>
          </div>


        </Card>
      </div>
    </div>
  )
}

export default WalletAddress