import React, { useState, useEffect } from 'react'
import {
    Card, Breadcrumbs, Typography,
    IconButton
} from '@mui/material'
import { Link } from 'react-router-dom'
import { Form, FormGroup, Label, Col, Input, Button, Table } from 'reactstrap'
import axios from 'axios';
import Swal from 'sweetalert2';
import Select from 'react-select'
import makeAnimated from 'react-select/animated';
import { IoIosAddCircle } from "react-icons/io";

const animatedComponents = makeAnimated();

function LiquidityManagement() {
    const [exchangeList, setExchangeLIst] = useState([]);
    const [liquidityNew, setLiquidity] = useState({
        exchange_name: "", current_bal: '',
        deposit: [],
        withdrawal: [],
    })
    const [addDeposite, setDeposite] = useState({
        exchange_name: '', deposit_amount: '', deposit_date: '', deposit_by: '', transaction_hash: '', remark: ''
    })
    const [withdrawal, setwithdrawal] = useState({
        exchange_name: '', amount: '', withdrawal_date: '', reason_for_withdrawal: ''
    })
    const addMoreDeposite = () => {
        var oldData = liquidityNew.deposit;
        oldData.push(addDeposite)

        console.log(oldData)
        setLiquidity((prev) => ({ ...prev, deposit: oldData }))
        setTimeout(() => {
            setDeposite({ exchange_name: '', deposit_amount: '', deposit_date: '', deposit_by: '', transaction_hash: '', remark: '' })
        }, 500);
    }
    const addWithDrall = () => {
        var oldWithdrl = liquidityNew.withdrawal;
        oldWithdrl.push(withdrawal)
        setLiquidity((prev) => ({ ...prev, withdrawal: oldWithdrl }))
        setTimeout(() => {
            setwithdrawal({ exchange_name: '', amount: '', withdrawal_date: '', reason_for_withdrawal: '' })
        }, 500);
    }

    const fetchData = async () => {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/exchange/list`)
        var data = []
        for (let i = 0; i < res.data.result.length; i++) {
            var dataA = { value: res.data.result[i].name, label: res.data.result[i].name };
            data.push(dataA)
        }
        setTimeout(() => {
            setExchangeLIst(data)
        }, 200);
    }
    const SaveBtn = async () => {
        // liquidity-management
        await axios.post(`${process.env.REACT_APP_API_URL}/liquidity-management/add`, liquidityNew)
        setTimeout(() => {
            Swal.fire({
                icon: "success",
                title: "Successfully saved",
                showConfirmButton: false,
                timer: 2000
            });
            setLiquidity({
                exchange_name: "", current_bal: '',
                deposit: [],
                withdrawal: [],
            })
        }, 1000);

    }
    useEffect(() => {
        fetchData();
    }, [])

    return (
        <div>
            <div className='box'>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/">
                        Dashboard
                    </Link>
                    <Typography sx={{ color: 'text.primary' }}>Liquidity Management </Typography>
                </Breadcrumbs>
            </div>
            <div className='text-inner'>
                <Card style={{ padding: '10px', paddingBottom: '60px' }}>
                    <h4>Liquidity Management</h4>
                    <div>
                        {/* {JSON.stringify(user)} */}

                    </div>
                    <hr />
                    <h6>Liquidity - USDT</h6>
                    <Form>
                        <FormGroup row>
                            <Label sm={2}>Exchange Name </Label>
                            <Col sm={4}>
                                <Select
                                    closeMenuOnSelect={false}
                                    components={animatedComponents}

                                    options={exchangeList}
                                    onChange={(e) => { setLiquidity((prev) => ({ ...prev, exchange_name: e.value })) }}
                                />
                            </Col>
                            <Label sm={2}>Current Balance: </Label>
                            <Col sm={4}>
                                <Input type="text" value={liquidityNew.current_bal}
                                    onChange={(e) => { setLiquidity((prev) => ({ ...prev, current_bal: e.target.value })) }} />
                            </Col>
                        </FormGroup>
                        <hr />

                        <h6>Liquidity Deposit - USDT</h6>
                        <Table>
                            <thead>
                                <tr>
                                    <th>Exchange Name</th>
                                    <th>Deposit Amount</th>
                                    <th>Deposit Date</th>
                                    <th>Deposited by</th>
                                    <th>Transaction Hash</th>
                                    <th>Remark</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {liquidityNew.deposit.length > 0 &&
                                    <>
                                        {liquidityNew.deposit.map((list, i) => (
                                            <tr key={i}>
                                                <td>{i + 1} </td>
                                                <td>{list.exchange_name.value} </td>
                                                <td>{list.deposit_amount} </td>
                                                <td>{list.deposit_date} </td>
                                                <td>{list.deposit_by} </td>
                                                <td>{list.transaction_hash} </td>
                                                <td> </td>
                                            </tr>
                                        ))}
                                    </>
                                }
                                <tr>
                                    <td>
                                        {/* <Input value={addDeposite.exchange_name} /> */}
                                        <Select
                                            closeMenuOnSelect={false}
                                            components={animatedComponents}
                                            options={exchangeList}
                                            onChange={(e) => { setDeposite((prev) => ({ ...prev, exchange_name: e })) }}
                                        />
                                    </td>
                                    <td>
                                        <Input type='number' value={addDeposite.deposit_amount}
                                            onChange={(e) => { setDeposite((prev) => ({ ...prev, deposit_amount: e.target.value })) }}
                                        />
                                    </td>
                                    <td>
                                        <Input type='date' value={addDeposite.deposit_date}
                                            onChange={(e) => { setDeposite((prev) => ({ ...prev, deposit_date: e.target.value })) }} />
                                    </td>
                                    <td>
                                        <Input type='text' value={addDeposite.deposit_by}
                                            onChange={(e) => { setDeposite((prev) => ({ ...prev, deposit_by: e.target.value })) }} />
                                    </td>
                                    <td>
                                        <Input type='text' value={addDeposite.transaction_hash}
                                            onChange={(e) => { setDeposite((prev) => ({ ...prev, transaction_hash: e.target.value })) }} />
                                    </td>
                                    <td>
                                        <Input type='text' value={addDeposite.remark}
                                            onChange={(e) => { setDeposite((prev) => ({ ...prev, remark: e.target.value })) }} />
                                    </td>
                                    <td>
                                        <IconButton color="success" onClick={addMoreDeposite}>
                                            <IoIosAddCircle />
                                        </IconButton>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                        <hr />
                        <h5>Withdrawal - USDT</h5>
                        <Table>
                            <thead>
                                <tr>
                                    <th>Exchange Name</th>
                                    <th>Amount</th>
                                    <th>Withdrawal Date</th>
                                    <th>Reason for withdrawal</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {liquidityNew.withdrawal.length > 0 &&
                                    <>
                                        {liquidityNew.withdrawal.map((list, i) => (
                                            <tr key={i}>
                                                <td>{i + 1} </td>
                                                <td>{list.exchange_name.value} </td>
                                                <td>{list.amount} </td>
                                                <td>{list.withdrawal_date} </td>
                                                <td>{list.reason_for_withdrawal} </td>
                                                <td></td>
                                            </tr>
                                        ))}
                                    </>
                                }
                                <tr>
                                    <td>
                                        <Select
                                            closeMenuOnSelect={false}
                                            components={animatedComponents}
                                            options={exchangeList}
                                            onChange={(e) => { setwithdrawal((prev) => ({ ...prev, exchange_name: e })) }}
                                        />
                                    </td>
                                    <td>
                                        <Input type='number' value={withdrawal.amount} onChange={(e) => { setwithdrawal((prev) => ({ ...prev, amount: e.target.value })) }} />
                                    </td>
                                    <td>
                                        <Input type='date'
                                            value={withdrawal.withdrawal_date} onChange={(e) => { setwithdrawal((prev) => ({ ...prev, withdrawal_date: e.target.value })) }} />
                                    </td>
                                    <td>
                                        <Input type='text'
                                            value={withdrawal.reason_for_withdrawal} onChange={(e) => { setwithdrawal((prev) => ({ ...prev, reason_for_withdrawal: e.target.value })) }} />
                                    </td>
                                    <td>
                                        <IconButton color="success" onClick={addWithDrall}>
                                            <IoIosAddCircle />
                                        </IconButton>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>


                        <br />
                        <Button type='button' color='success' block onClick={SaveBtn}>Save</Button>
                    </Form>
                </Card>
            </div>
        </div>
    )
}

export default LiquidityManagement