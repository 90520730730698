import {
    Card, TextField, Breadcrumbs, Typography, FormControl,
    Button,
    IconButton
} from '@mui/material'
import { FaTrash } from 'react-icons/fa6'
import { Col, Row, Table } from 'reactstrap'
import { Link } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import axios from 'axios';
import Swal from 'sweetalert2';

function TransferToken() {
    const [newTransfer, setTransfer] = useState({ address: '', number_token: '', issue_date: '', release_date: '', note: '', user_id: '' });
    const [transfetList, setTransferLIst] = useState([]);

    const transferToken = async () => {
        if (newTransfer.address === '') {
            return alert('Please enter address.!')
        }
        if (newTransfer.number_token === '') {
            return alert('Please enter Token.!')
        }
        if (newTransfer.issue_date === '') {
            return alert('Please enter Issue Date.!')
        }
        if (newTransfer.release_date === '') {
            return alert('Please enter Release Date.!')
        }
        const respData = await axios.post(`${process.env.REACT_APP_API_URL}/transfer/new`, newTransfer)
        console.log(respData)
        setTransfer({ address: '', number_token: '', issue_date: '', release_date: '', note: '' })
        Swal.fire({
            icon: "success",
            title: "Successfully saved",
            showConfirmButton: false,
            timer: 2000
        });
        setTimeout(() => {
            transferFetch()
        }, 1000);
    }
    const transferFetch = async () => {
        const resData = await axios.get(`${process.env.REACT_APP_API_URL}/transfer/list`)
        if (resData.data) {
            setTransferLIst(resData.data.result)
        }        
    }
    const delData = async (data) => {
        await axios.post(`${process.env.REACT_APP_API_URL}/transfer/delete`, data)
        transferFetch()
    }
    const deleteRow = async (data) => {
        Swal.fire({
            title: "Are you sure?",
            text: "",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then((result) => {
            if (result.isConfirmed) {
                delData(data)
                setTimeout(() => {
                    Swal.fire({
                        title: "Deleted!",
                        text: "Your file has been deleted.",
                        icon: "success"
                    });
                }, 1000);
            }
        });
    }
    useEffect(() => {
        transferFetch()
        if (localStorage.getItem('user')) {
            const data = JSON.parse(localStorage.getItem('user'))
            setTransfer((prev) => ({ ...prev, user_id: data.id }))
        }
    }, [])
    return (
        <div>
            <div className='box'>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/">
                        Dashboard
                    </Link>
                    <Typography sx={{ color: 'text.primary' }}>Transfer token</Typography>
                </Breadcrumbs>
            </div>
            <div className='text-inner'>
                <Card style={{ padding: '10px' }}>
                    <h4>Transfer token</h4>
                    <div className="row">
                        <div className='col-sm-12' >
                            <form>
                                <FormControl sx={{ m: 1, }} variant="outlined">
                                    <TextField
                                        label="Address" size='small'
                                        variant="outlined" type='text'
                                        InputLabelProps={{ shrink: true }}
                                        value={newTransfer.address}
                                        onChange={(e) => { setTransfer((prev) => ({ ...prev, address: e.target.value })) }}

                                    />
                                </FormControl>
                                <FormControl sx={{ m: 1, }} variant="outlined">
                                    <TextField
                                        label="Number of token" size='small'
                                        variant="outlined" type='number'
                                        InputLabelProps={{ shrink: true }}
                                        value={newTransfer.number_token}
                                        onChange={(e) => { setTransfer((prev) => ({ ...prev, number_token: e.target.value })) }}
                                    />
                                </FormControl>
                                <FormControl sx={{ m: 1, }} variant="outlined">
                                    <TextField
                                        label="Issue Date" size='small'
                                        variant="outlined" type='date'
                                        InputLabelProps={{ shrink: true }}
                                        value={newTransfer.issue_date}
                                        onChange={(e) => { setTransfer((prev) => ({ ...prev, issue_date: e.target.value })) }}
                                    />
                                </FormControl>
                                <FormControl sx={{ m: 1, }} variant="outlined">
                                    <TextField
                                        label="Release  Date" size='small'
                                        variant="outlined" type='date'
                                        InputLabelProps={{ shrink: true }}
                                        value={newTransfer.release_date}
                                        onChange={(e) => { setTransfer((prev) => ({ ...prev, release_date: e.target.value })) }}
                                    />
                                </FormControl>
                                <FormControl sx={{ m: 1, }} variant="outlined">
                                    <TextField
                                        label="Note" size='small'
                                        variant="outlined" type='text'
                                        InputLabelProps={{ shrink: true }}
                                        value={newTransfer.note}
                                        onChange={(e) => { setTransfer((prev) => ({ ...prev, note: e.target.value })) }}
                                    />
                                </FormControl>
                                <FormControl sx={{ m: 1, }} variant="outlined">
                                    <Button variant="contained" onClick={transferToken}>Send</Button>
                                </FormControl>
                            </form>
                        </div>
                    </div>
                </Card>
                <Card className='pt-5 pb-5 ps-5'>
                    <Row className='bg-white width-100'>
                        <Col md={12} className=''>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Sl</th>
                                        <th>Address</th>
                                        <th>Issue Date</th>
                                        <th>Release Date</th>
                                        <th>Number of token</th>
                                        <th>Note</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {transfetList.length > 0 &&
                                        <>
                                            {transfetList.map((list, i) => (
                                                <tr key={i}>
                                                    <td>{i + 1} </td>
                                                    <td>{list.address} </td>
                                                    <td>{list.issue_date} </td>
                                                    <td>{list.release_data} </td>
                                                    <td>{list.num_token} </td>
                                                    <td>{list.note} </td>
                                                    <td>
                                                        <IconButton color="error" onClick={() => { deleteRow(list) }}
                                                            aria-label="add to shopping cart">
                                                            <FaTrash />
                                                        </IconButton>
                                                    </td>
                                                </tr>
                                            ))}
                                        </>
                                    }
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Card>
            </div>
        </div>

    )
}

export default TransferToken