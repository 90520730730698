import { Card, TextField, Breadcrumbs, Typography, FormControl, Autocomplete, Button, IconButton } from '@mui/material'
import { Link } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Swal from 'sweetalert2';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import { AiOutlineUserAdd } from 'react-icons/ai'


import { Table, Modal, ModalHeader, ModalBody, ModalFooter, Input, Label } from 'reactstrap'

import { FaEdit } from 'react-icons/fa';
import { FaTrash } from 'react-icons/fa6';
import { IoIosAddCircle } from 'react-icons/io';

function Language() {

    const [modal, setModal] = useState(false);
    const [addNew, setAddnew] = useState({ text_key: '', full_text: '' });
    const [languageList, setLanList] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    const filteredData = languageList.filter((row) =>
        row.full_text.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const toggle = () => setModal(!modal);

    const saveLang = async () => {
        if (addNew.text_key === '' || addNew.full_text === '') {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Please enter key and full text!",
                footer: ''
            });
            return
        }
        if (addNew.id) {
            console.log('update');
            await axios.post(`${process.env.REACT_APP_API_URL}/language/add`, addNew)
            setAddnew({ text_key: '', full_text: '' })
            toggle();
            setTimeout(() => {
                fetchLang();
                Swal.fire({
                    icon: "success",
                    title: "Successfully updated",
                    showConfirmButton: false,
                    timer: 2000
                });
            }, 1000);
            return
        }
        await axios.post(`${process.env.REACT_APP_API_URL}/language/add`, addNew)
        setAddnew({ text_key: '', full_text: '' })
        setTimeout(() => {
            fetchLang();
            Swal.fire({
                icon: "success",
                title: "Successfully saved",
                showConfirmButton: false,
                timer: 2000
            });
        }, 1000);
    }
    const editData = async (data) => {
        setAddnew(data);
        setModal(!modal)
    }
    const deleteData = async (data) => {
        await axios.post(`${process.env.REACT_APP_API_URL}/language/delete`, data)
        setTimeout(() => {
            fetchLang();
            Swal.fire({
                icon: "success",
                title: "Successfully deleted",
                showConfirmButton: false,
                timer: 2000
            });
        }, 1000);
    }

    const fetchLang = async () => {
        const resR = await axios.get(`${process.env.REACT_APP_API_URL}/language/list`);
        setLanList(resR.data.result)
    }
    useEffect(() => {
        fetchLang();
    }, [])
    return (
        <div>
            <div className='box'>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/">
                        Dashboard
                    </Link>
                    <Typography sx={{ color: 'text.primary' }}>Language</Typography>
                </Breadcrumbs>
            </div>
            <div className='text-inner'>
                <Card style={{ padding: '10px' }}>
                    <h4>Language</h4>
                    <div className="row">
                        <div className='col-sm-12'>
                            <Label>Search text</Label>
                            <Input
                                type="text"
                                placeholder="Search by text..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                            <br />
                            <hr />
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Sl</th>
                                        <th>Key</th>
                                        <th>Text</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {languageList.length > 0 &&
                                        <>
                                            {filteredData.map((list, i) => (
                                                <tr key={i}>
                                                    <td>{i + 1}</td>
                                                    <td>{list.text_key}</td>
                                                    <td>{list.full_text}</td>
                                                    <td>
                                                        <IconButton color="error" aria-label="add to shopping cart"
                                                            onClick={() => { deleteData(list) }}>
                                                            <FaTrash />
                                                        </IconButton>
                                                        <IconButton color="warning" aria-label="add to shopping cart"
                                                            onClick={() => { editData(list) }}>
                                                            <FaEdit />
                                                        </IconButton>
                                                        <Modal isOpen={modal} toggle={toggle} size='lg'>
                                                            <ModalHeader toggle={toggle}>Update </ModalHeader>
                                                            <ModalBody className='pt-5 pb-5'>
                                                                <Input
                                                                    bsSize="lg"
                                                                    type="textarea"
                                                                    value={addNew.full_text}
                                                                    onChange={(e) => { setAddnew((prev) => ({ ...prev, full_text: e.target.value })) }} />

                                                            </ModalBody>
                                                            <ModalFooter>
                                                                <Button color="primary" onClick={saveLang}>
                                                                    Update
                                                                </Button>{' '}
                                                                <Button color="secondary" onClick={toggle}>
                                                                    Cancel
                                                                </Button>
                                                            </ModalFooter>
                                                        </Modal>
                                                    </td>
                                                </tr>
                                            ))}
                                        </>
                                    }

                                    <tr>
                                        <td>{languageList.length + 1} </td>
                                        <td>
                                            <TextField id="standard-basic" label="Key" variant="standard"
                                                value={addNew.text_key}
                                                onChange={(e) => { setAddnew((prev) => ({ ...prev, text_key: e.target.value })) }} />
                                        </td>
                                        <td>
                                            <TextField id="standard-basic" label="Text" variant="standard" value={addNew.full_text}
                                                onChange={(e) => { setAddnew((prev) => ({ ...prev, full_text: e.target.value })) }} />
                                        </td>
                                        <td>
                                            <IconButton color="success" aria-label="add to shopping cart"
                                                onClick={saveLang}>
                                                <IoIosAddCircle fontSize={30} />
                                            </IconButton>

                                        </td>
                                    </tr>
                                </tbody>
                            </Table>

                        </div>
                    </div>
                </Card>
            </div>
        </div>
    )
}

export default Language