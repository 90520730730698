import React, { useState } from 'react'

import { Button } from 'reactstrap';
import { Card, TextField, Breadcrumbs, Typography, FormControl, Autocomplete, IconButton, Box } from '@mui/material'

import Editor from 'react-simple-wysiwyg';
import axios from 'axios';
import Swal from 'sweetalert2';
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from 'react-router-dom';

function Addnews() {
    const [newsData, setNewsData] = useState({ subject: '', body: '', date: '', hrf_link: '' });
    const onChange = () => {
        console.log()
    }
    const saveNews = async () => {
        const resData = await axios.post(`${process.env.REACT_APP_API_URL}/news/add`, newsData)
        console.log(newsData)
        setTimeout(() => {
            Swal.fire({
                title: "Insert success!",
                icon: "success",
                draggable: true
            });
            setNewsData({ subject: '', body: '', date: '', hrf_link: '' })
        }, 1000);
    }
    return (
        <div>
            <div className='box'>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/">
                        Dashboard
                    </Link>
                    <Typography sx={{ color: 'text.primary' }}>News </Typography>
                </Breadcrumbs>
            </div>
            <div className='text-inner'>
                <Card style={{ padding: '10px' }}>
                    <h4>Add news</h4>
                    <div className="row">
                        <div className='col-sm-12'>
                            <Box>
                                <div>
                                    <h5>Add news</h5>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-10'>
                                        <TextField id="outlined-basic" label="Subject" variant="outlined"
                                            sx={{ '& > :not(style)': { m: 1, width: '90ch' } }}
                                            onChange={(e) => { setNewsData((prev) => ({ ...prev, subject: e.target.value })) }}
                                            value={newsData.subject} />


                                        <TextField id="outlined-basic" label="Date" variant="outlined"
                                            sx={{ '& > :not(style)': { m: 1, width: '90ch' } }}
                                            onChange={(e) => { setNewsData((prev) => ({ ...prev, date: e.target.value })) }}
                                            value={newsData.date} type='date' />


                                        <Editor
                                            containerProps={{ style: { width: '100%' } }}
                                            onChange={(e) => { setNewsData((prev) => ({ ...prev, body: e.target.value })) }}
                                            value={newsData.body}
                                        />

                                        <TextField id="outlined-basic" label="Main Link" variant="outlined"
                                            sx={{ '& > :not(style)': { m: 1, width: '90ch' } }}
                                            onChange={(e) => { setNewsData((prev) => ({ ...prev, hrf_link: e.target.value })) }}
                                            value={newsData.hrf_link} />
                                        <div>
                                            
                                        </div>
                                        <br />
                                        <Button color='success' block onClick={saveNews}>Save</Button>
                                    </div>
                                </div>
                            </Box>
                        </div>
                    </div>
                </Card>
            </div>


        </div>
    )
}

export default Addnews