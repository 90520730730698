import axios from "axios";

const fetchList = async () => {
    const resR = await axios.get(`${process.env.REACT_APP_API_URL}/language/list`);
    //setLanList(resR.data.result)
    localStorage.setItem('language', JSON.stringify(resR.data.result))
    window.location.reload();
}
export function getLanguage(name) {
    if (localStorage.getItem('language') === null) {
        fetchList();
        setTimeout(() => {
            const list = JSON.parse(localStorage.getItem('language'));
            console.log(getLanguage)
            for (let i = 0; i < list.length; i++) {
                if (list[i].text_key == name) {
                    // text = list[i].full_text;
                    return list[i].full_text.replaceAll("|", "'");
                }
            }
        }, 1500);


    } else {
        const list = JSON.parse(localStorage.getItem('language'));
        console.log(getLanguage)
        for (let i = 0; i < list.length; i++) {
            if (list[i].text_key == name) {
                // text = list[i].full_text;
                return list[i].full_text.replaceAll("|", "'");
            }
        }
    }



}