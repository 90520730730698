import React, { useState } from 'react';
import logo from '../img/logo.png';
import { Link, Route, Routes } from 'react-router-dom';
import { InputGroup, Input, Button } from 'reactstrap';

import fb from '../img/social/fb.png';
import twiter from '../img/social/twr.png';
import instra from '../img/social/ins.png';
import linkdin from '../img/social/link.png';
import tl from '../img/social/t.png';
import youtube from '../img/social/yout.png';
import tel from '../img/social/tel.png';

import { MdEmail } from "react-icons/md";

import Main from './Main';
import Location from './Location';
import Tokenomics from './Tokenomics';
import Cex from './Cex';
import Community from './Community';
import Ambassador from './Ambassador';
import Investor from './Investor';

import { FaBars } from "react-icons/fa";
import ViewMore from './ViewMore';
import NewEvent from './NewEvent';
import EventPage from './EventPage';
import axios from 'axios';
import Swal from 'sweetalert2'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import * as Yup from 'yup';
import coinmarket from '../img/coin_market.png';
import coingk from '../img/coingk.png';
import footrImg from '../img/footer_img.png';

function Home() {
    const [activeItem, setActiveItem] = useState(null);
    const [clicka, setClicka] = useState(false);
    const [subsEmail, setSubsEmail] = useState('');
    const [open, setOpen] = useState(false);
    const emailSchema = Yup.string()
        .email('Invalid email format')
        .required('Email is required');

    const responsiveMenu = () => {
        setClicka(!clicka);
        var menu = document.getElementById("menu");
        if (menu.className === "menu") {
          menu.className += " responsive";
        }
        else {
          menu.className = "menu";
        }
    };
    const handleClick = (index) => {
        setActiveItem(index);
        responsiveMenu();
    };
    const handalSubs = async () => {
        //if (subsEmail === '') { alert('Please enter email!') };
        emailSchema
            .validate(subsEmail)
            .then(async () => {
                setOpen(true);
                const resSubs = await axios.post(`${process.env.REACT_APP_API_URL}/subsemail/add`, { email: subsEmail });
                setOpen(false);
                setSubsEmail('');
                Swal.fire({
                    icon: "success",
                    title: "Subscription Successful!",
                    showConfirmButton: false,
                    timer: 2000
                });
            })
            .catch((error) => {
                return alert(error.message);
                // setValidationError(error.message);
            });
    };

    return (
        <div>
            <div className="header">
                <Link to="/">
                    <img src={logo} className="header-logo" alt="" />
                </Link>
                <Link to="https://coinmarketcap.com/currencies/nugget-trap-gold-token/" target="_blank">
                    <img src={coinmarket} className="img-a" alt="" />
                </Link>
                <Link to="https://www.coingecko.com/en/coins/nugget-trap" target="_blank">
                    <img src={coingk} className="img-a" alt="" />
                </Link>
                <div id="menu" class="menu">
                    <Link to="https://nuggettrap.com/uploads/Nugget Trap Tokenomics White Paper 7Nov24.pdf" target="_blank">WHITEPAPER</Link>
                    <div class="menu-ex">
                        <button class="menu-ex-item">PROSPECTORS</button>
                        <div class="menu-ex-content">
                            <Link onClick={() => handleClick(1)} to="/home/prospectors">PROSPECTORS</Link>
                            <Link onClick={() => handleClick(0)} to="/home/assay-report">ASSAY REPORT</Link>
                        </div>
                    </div>
                    <Link onClick={() => handleClick(2)} to="/home/tokenomics">TOKENOMICS</Link>
                    <Link onClick={() => handleClick(3)} to="/home/cex">CEX</Link>
                    <Link onClick={() => handleClick(4)} to="/home/community">COMMUNITY</Link>
                    <div class="menu-ex">
                        <button class="menu-ex-item">NEWS & EVENTS</button>
                        <div class="menu-ex-content">
                            <Link onClick={() => handleClick(6)} to="/home/news">NEWS</Link>
                            <Link onClick={() => handleClick(7)} to="/home/event">EVENTS</Link>
                        </div>
                    </div>
                    <Link onClick={() => handleClick(5)} to="/home/ambassador">AMBASSADOR PROGRAM</Link>
                    <Link to="/login" className="login">LOGIN</Link>
                    <a href="javascript:void(0);" class="icon" onClick={responsiveMenu}><FaBars /></a>
                </div>
            </div>
            <Routes>
                <Route path="/" element={<Main />}></Route>
                <Route path="/prospectors" element={<Investor />}></Route>
                <Route path="/assay-report" element={<Location />}></Route>
                <Route path="/tokenomics" element={<Tokenomics />}></Route>
                <Route path="/cex" element={<Cex />}></Route>
                <Route path="/community" element={<Community />}></Route>
                <Route path="/news" element={<NewEvent />}></Route>
                <Route path="/event" element={<EventPage />}></Route>
                <Route path="/ambassador" element={<Ambassador />}></Route>
                <Route path="/view-more" element={<ViewMore />}></Route>
                {/* <Route path='/login' element={<Ambassador />} ></Route> */}
            </Routes>
            <div className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-6 subscribe">
                            <div className="pt-5">
                                <img className="footer-logo" src={logo} alt="" />
                                <span className="desc-text">We would like to welcome you to the Nugget Trap gold placer mine project</span>
                                <div className="subs-form">
                                    <InputGroup>
                                        <Input className="subs" value={subsEmail}
                                            onChange={(e) => { setSubsEmail(e.target.value) }} />
                                        <Button color="primary" onClick={handalSubs}>
                                            <MdEmail /> Subscribe
                                        </Button>
                                    </InputGroup>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-3 pt-5 quick-links">
                            <h5 className="pb-3">Quick Links</h5>
                            <ul>
                                <li><Link to="/">Home</Link></li>
                                <li><Link to="/home/prospectors">Prospectors</Link></li>
                                <li><Link to="/home/assay-report">Assay Report</Link></li>
                                <li><Link to="https://nuggettrap.com/uploads/NuggetTrapPrivacyPolicy.pdf" target="_blank">Privacy Policy</Link></li>
                                <li><Link to="https://nuggettrap.com/uploads/NuggetTrapRiskPolicy.pdf" target="_blank">Risk Policy</Link></li>
                                <li><Link to="https://nuggettrap.com/uploads/TermsandConditions.pdf">Terms and Conditions</Link></li>
                            </ul>
                        </div>
                        <div className="col-sm-3 pt-5 social-links">
                            <h5 className="pb-3">Follow Us</h5>
                            <ul>
                                <li><Link to="https://www.facebook.com/profile.php?id=61552312260533" target="_blank"><img src={fb} alt="" /></Link></li>
                                <li><Link to="https://x.com/Nuggettrapgold" target="_blank"><img src={twiter} alt="" /></Link></li>
                                <li><Link to="https://www.instagram.com/nuggettrapgold/" target="_blank"><img src={instra} alt="" /></Link></li>
                                <li><Link to="https://www.linkedin.com/company/nugget-trap" target="_blank"><img src={linkdin} alt="" /></Link></li>
                                <li><Link to="https://www.threads.net/@nuggettrapgold" target="_blank"><img src={tl} alt="" /></Link></li>
                                <li><Link to="https://www.youtube.com/@GoingforGoldNGT" target="_blank"><img src={youtube} alt="" /></Link></li>
                                <li><Link to="https://t.me/nuggettrapgold" target="_blank"><img src={tel} alt="" /></Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="copy-right">
                <p className="copy-text">Copyright © {new Date().getFullYear()} Nugget Trap. All Rights Reserved.</p>
            </div>
            <Backdrop sx={{ color: "white", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    )
}
export default Home