import React, { useEffect, useState } from 'react'

import { IconButton, InputAdornment, TextField } from '@mui/material';
import { FaHome } from 'react-icons/fa';
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';

import { AiOutlineSetting, AiOutlineGlobal, AiOutlineHdd, AiOutlineMenu, AiOutlineSearch, AiTwotoneShop, AiOutlineSolution, AiOutlineLaptop, AiOutlineSend, AiFillDatabase } from "react-icons/ai";
//import logo from '../../images/newlogo.png'
import logo from '../img/newlogo.png'

import RightMenu from './comon/RightMenu';
import { Link, Route, Routes, useNavigate } from 'react-router-dom';
import Home from './Home';
import Rolemaster from './Rolemaster';
import SubscriptionMaster from './SubscriptionMaster';
import Invite from './Invite';
import Individual from './Individual';
import Business from './Business';
import RolemasterList from './RolemasterList';
import SubscriptionMasterList from './SubscriptionMasterList';
import InviteList from './InviteList';
import TreasuryList from './TreasuryList';
import TreasuryNew from './TreasuryNew';
import TimePeriod from './TimePeriod';
import BusinessList from './BusinessList';
import Addform from './Addform';
import CommunityQuestions from './CommunityQuestions';
import CommunityList from './CommunityList';
import FeedbackList from './FeedbackList';
import AmbassadorList from './AmbassadorList';
import TokenRegistorList from './TokenRegistorList';
import SubscriptionEmailList from './SubscriptionEmailList';
import AddNews from './AddNews';
import AddPolls from './AddPolls';
import SubmitedPools from './SubmitedPools';
import PollsDetails from './PollsDetails';
import ListPolls from './ListPolls';
import { IoMdWallet } from "react-icons/io";

import { CgProfile } from "react-icons/cg";
import WalletAddress from './WalletAddress';
import TokenPortfolio from './TokenPortfolio';
import Announcements from './Announcements';
import Support from './Support';
import Settings from './Settings';
import TransferToken from './TransferToken';
import AnnouncementsAdd from './AnnouncementsAdd';
import MarketMaker from './MarketMaker';
import ExchangeAssignment from './ExchangeAssignment';
import { MdLanguage } from 'react-icons/md';
import Language from './Language';
import Addnews from '../component/Addnews';
import ExchangeManagement from './ExchangeManagement';
import LiquidityManagement from './LiquidityManagement';
import TreasuryEdit from './TreasuryEdit';
import AddExchanges from '../component/AddExchanges';
import TradingPairs from '../component/TradingPairs';
import MarketMarkerList from './MarketMarkerList';
import ExchangeAssignmentList from './ExchangeAssignmentList';
import LiquidityManagementList from './LiquidityManagementList';



function Dashboard() {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [user, setUser] = useState(null);

    const handleDrawerToggle = () => {
        setOpen(!open);
    };
    useEffect(() => {
        const userData = localStorage.getItem('user');
        console.log(JSON.parse(userData))
        if (userData === null) {
            navigate('/login')
        } else {
            setUser(JSON.parse(userData));
        }
    }, [])
    return (
        <div style={{ display: 'flex' }}>
            {/* backgroundColor="#673ab7" */}
            <Sidebar style={{ height: '700px' }} className="custom-sidebar" toggled={open}>
                <div className='dashboard'>
                    <img src={logo} alt="logo" className='logo' />
                    <div>
                        <h3 className='font-15'>Welcome {user?.name} </h3>
                    </div>
                </div>
                {user?.level === 1 ? (
                    <>
                        <Menu>
                            

                            <MenuItem component={<Link to="/dashboard" />} icon={<FaHome />}> Dashboard </MenuItem>
                            <MenuItem component={<Link to="/dashboard/language" />} icon={<MdLanguage /> }> Language </MenuItem>
                            <MenuItem component={<Link to="/dashboard/news-add" />} icon={<MdLanguage /> }> Add News </MenuItem>

                            <MenuItem component={<Link to="/dashboard/transfer-token" />} icon={<AiOutlineHdd />}>Transfers The Tokens</MenuItem>
                            <MenuItem component={<Link to="/dashboard/add-announcements" />} icon={<AiOutlineHdd />}>Add Announcements</MenuItem>
                            <MenuItem component={<Link to="/dashboard/support" />} icon={<AiOutlineHdd />}>Support Center</MenuItem>

                            <MenuItem component={<Link to="/dashboard/add-exchanges" />} icon={<AiOutlineHdd />}>Add Exchanges</MenuItem>
                            <MenuItem component={<Link to="/dashboard/add-trading" />} icon={<AiOutlineHdd />}>Add Trading Pairs</MenuItem>

                            <MenuItem component={<Link to="/dashboard/market-marker" />} icon={<AiOutlineHdd />}>Create Market Maker</MenuItem>
                            <MenuItem component={<Link to="/dashboard/market-marker-list" />} icon={<AiOutlineHdd />}>Market Maker List</MenuItem>

                            <MenuItem component={<Link to="/dashboard/exchange-assignment" />} icon={<AiOutlineHdd />}>Exchange Assignment</MenuItem>
                            <MenuItem component={<Link to="/dashboard/exchange-assignment-list" />} icon={<AiOutlineHdd />}>Exchange Assignment List</MenuItem>

                            <MenuItem component={<Link to="/dashboard/exchange-management" />} icon={<AiOutlineHdd />}>Exchange Management</MenuItem>

                            <MenuItem component={<Link to="/dashboard/liquidity-management" />} icon={<AiOutlineHdd />}>Liquidity Management</MenuItem>
                            <MenuItem component={<Link to="/dashboard/liquidity-management-list" />} icon={<AiOutlineHdd />}>Liquidity Management List</MenuItem>

                            {/* old */}
                            <MenuItem component={<Link to="/dashboard/time-period" />} icon={<AiOutlineHdd />}>Set time period  </MenuItem>
                            <MenuItem component={<Link to="/dashboard/community-questions" />} icon={<AiOutlineHdd />}>Set Community questions  </MenuItem>
                            <SubMenu label="Community" icon={<AiOutlineLaptop />}>
                                <MenuItem component={<Link to="/dashboard/community-list" />} icon={<AiOutlineSolution />} > Community </MenuItem>
                                <MenuItem component={<Link to="/dashboard/feedback-list" />} icon={<AiTwotoneShop />} > Feedback </MenuItem>
                            </SubMenu>
                            <MenuItem component={<Link to="/dashboard/add-news" />} icon={<AiOutlineHdd />}>Add News  </MenuItem>
                            <MenuItem component={<Link to="/dashboard/add-polls" />} icon={<AiOutlineHdd />}>Add Polls</MenuItem>
                            <MenuItem component={<Link to="/dashboard/list-polls" />} icon={<AiOutlineHdd />}>Polls Lists</MenuItem>

                            <MenuItem component={<Link to="/dashboard/submited-polls" />} icon={<AiOutlineHdd />}>View Submited Polls </MenuItem>

                            <MenuItem component={<Link to="/dashboard/ambassador-list" />} icon={<AiOutlineHdd />}>Ambassador </MenuItem>
                            <MenuItem component={<Link to="/dashboard/token-registration-list" />} icon={<AiOutlineHdd />}>Token registration list </MenuItem>
                            <MenuItem component={<Link to="/dashboard/add-form" />} icon={<AiOutlineHdd />}>Add form </MenuItem>
                            <MenuItem component={<Link to="/dashboard/rolemaster" />} icon={<AiOutlineHdd />}>User Category Role Master </MenuItem>
                            <MenuItem component={<Link to="/dashboard/subscription-master-list" />} icon={<AiOutlineSetting />}> Subscription Master </MenuItem>
                            <MenuItem component={<Link to="/dashboard/invite-list" />} icon={<AiOutlineSend />} > Invite </MenuItem>
                            <MenuItem component={<Link to="/dashboard/treasury-list" />} icon={<AiFillDatabase />} > Treasury </MenuItem>
                            <MenuItem component={<Link to="/dashboard/subscription-email" />} icon={<AiFillDatabase />} >Subscription Email List </MenuItem>
                            <SubMenu label="Shareholder registration" icon={<AiOutlineLaptop />}>
                                <MenuItem component={<Link to="/dashboard/individual" />} icon={<AiOutlineSolution />} > Individual </MenuItem>
                                <MenuItem component={<Link to="/dashboard/business" />} icon={<AiTwotoneShop />} > Business </MenuItem>
                                <MenuItem component={<Link to="/dashboard/business-list" />} icon={<AiTwotoneShop />} > Business </MenuItem>
                            </SubMenu>
                            <MenuItem icon={<AiOutlineGlobal />}> Reports & Graphs </MenuItem>
                        </Menu>
                    </>
                ) : (
                    <>
                        <Menu>
                            <MenuItem component={<Link to="/dashboard" />} icon={<FaHome />}> Dashboard </MenuItem>
                            <MenuItem component={<Link to="/dashboard/wallet-address" />} icon={<IoMdWallet />}> Wallet Address </MenuItem>
                            <MenuItem component={<Link to="/dashboard/token-portfolio" />} icon={<CgProfile />}> Token Profile </MenuItem>
                            <MenuItem component={<Link to="/dashboard/announcements" />} icon={<FaHome />}> Announcements & Updates </MenuItem>
                            <MenuItem component={<Link to="/dashboard/support" />} icon={<FaHome />}> Support </MenuItem>
                            <MenuItem component={<Link to="/dashboard/settings" />} icon={<FaHome />}> Settings </MenuItem>
                        </Menu>
                    </>
                )}


            </Sidebar>
            <div className='right'>
                <div className='in-hdr'>
                    <div>

                        <IconButton size="large" onClick={handleDrawerToggle}>
                            <AiOutlineMenu />
                        </IconButton>
                        <TextField
                            label="Search.."
                            id="outlined-start-adornment"
                            sx={{ m: 1 }}
                            slotProps={{
                                input: {
                                    startAdornment: <InputAdornment position="start">
                                        <AiOutlineSearch />
                                    </InputAdornment>,
                                },
                            }}
                        />
                    </div>

                    <RightMenu />
                </div>
                <Routes>
                    <Route path='/' element={<Home />}></Route>
                    <Route path='/time-period' element={<TimePeriod />}></Route>
                    <Route path='/add-announcements' element={<AnnouncementsAdd />}></Route>
                    <Route path='/add-form' element={<Addform />}></Route>
                    <Route path='/market-marker' element={<MarketMaker />}></Route>
                    <Route path='/market-marker-list' element={<MarketMarkerList />}></Route>

                    <Route path='/exchange-assignment' element={<ExchangeAssignment />}></Route>
                    <Route path='/exchange-assignment-list' element={<ExchangeAssignmentList />}></Route>

                    <Route path='/exchange-management' element={<ExchangeManagement />}></Route>

                    <Route path='/liquidity-management' element={<LiquidityManagement />}></Route>
                    <Route path='/liquidity-management-list' element={<LiquidityManagementList />}></Route>

                    <Route path='/language' element={<Language />}></Route>
                    <Route path='/news-add' element={<Addnews />}></Route>

                    <Route path='/add-exchanges' element={<AddExchanges />}></Route>
                    <Route path='/add-trading' element={<TradingPairs />}></Route>

                    <Route path='/community-questions' element={<CommunityQuestions />}></Route>
                    <Route path='/community-list' element={<CommunityList />}></Route>
                    <Route path='/feedback-list' element={<FeedbackList />}></Route>
                    <Route path='/ambassador-list' element={<AmbassadorList />}></Route>
                    <Route path='/token-registration-list' element={<TokenRegistorList />}></Route>
                    <Route path='/subscription-email' element={<SubscriptionEmailList />}></Route>
                    <Route path='/add-news' element={<AddNews />}></Route>
                    <Route path='/add-polls' element={<AddPolls />}></Route>
                    <Route path='/list-polls' element={<ListPolls />}></Route>
                    <Route path='/submited-polls' element={<SubmitedPools />}></Route>
                    <Route path='/view-details' element={<PollsDetails />}></Route>

                    <Route path='/rolemaster' element={<Rolemaster />}></Route>
                    <Route path='/rolemaster-list' element={<RolemasterList />}></Route>
                    <Route path='/subscription-master' element={<SubscriptionMaster />}></Route>
                    <Route path='/subscription-master-list' element={<SubscriptionMasterList />}></Route>
                    <Route path='/invite' element={<Invite />}></Route>
                    <Route path='/invite-list' element={<InviteList />}></Route>
                    <Route path='/individual' element={<Individual />}></Route>
                    <Route path='/business' element={<Business />}></Route>
                    <Route path='/business-list' element={<BusinessList />}></Route>
                    <Route path='/treasury-add' element={<TreasuryNew />}></Route>
                    <Route path='/treasury-edit' element={<TreasuryEdit />}></Route>
                    <Route path='/treasury-list' element={<TreasuryList />}></Route>
                    <Route path='/wallet-address' element={<WalletAddress />}></Route>
                    <Route path='/token-portfolio' element={<TokenPortfolio />}></Route>
                    <Route path='/transfer-token' element={<TransferToken />}></Route>
                    <Route path='/announcements' element={<Announcements />}></Route>
                    <Route path='/support' element={<Support />}></Route>
                    <Route path='/settings' element={<Settings />}></Route>

                </Routes>


            </div>
        </div>
    )
}

export default Dashboard