import React, { useState, useEffect } from 'react'
import {
    Card, Breadcrumbs, Typography
} from '@mui/material'
import { Link } from 'react-router-dom'
import { Form, FormGroup, Label, Col, Input, Button } from 'reactstrap'
import { PiUserCircleGearLight } from "react-icons/pi";
import axios from 'axios';
import Swal from 'sweetalert2';
import Select from 'react-select'
import makeAnimated from 'react-select/animated';
const animatedComponents = makeAnimated();


function ExchangeAssignment() {
    const [newExchange, setNewExchange] = useState({
        exchange_name: '', trading_pairs: '',
        contract_start_date: '', payment_due_date: '', payment_frequency: '', market_maker: ''
    })
    const [listMM, setListMM] = useState([]);

    const [exchangeList, setExchangeLIst] = useState([]);
    const [TradingLIst, setTradingLIst] = useState([]);


    const SaveData = async () => {
        if (newExchange.exchange_name === '' || newExchange.contract_start_date === '' || newExchange.payment_frequency === '') {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Please enter all details!",
                footer: ''
            });
            return
        }
        await axios.post(`${process.env.REACT_APP_API_URL}/exchange-assignment/add`, newExchange)
        setNewExchange({
            exchange_name: '', trading_pairs: '',
            contract_start_date: '', payment_due_date: '', payment_frequency: '', market_maker: ''
        })
        Swal.fire({
            icon: "success",
            title: "Successfully saved",
            showConfirmButton: false,
            timer: 2000
        });
    }
    const fetchTrading = async () => {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/trading-pairs/list`)
        var data = []
        for (let i = 0; i < res.data.result.length; i++) {
            var dataA = { value: res.data.result[i].name, label: res.data.result[i].name };
            data.push(dataA)
        }
        setTimeout(() => {
            setTradingLIst(data)
        }, 200);

        //setTradingLIst(res.data.result)
    }
    useEffect(() => {
        fetchTrading();
    }, [])
    const fetchData = async () => {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/exchange/list`)
        var data = []
        for (let i = 0; i < res.data.result.length; i++) {
            var dataA = { value: res.data.result[i].name, label: res.data.result[i].name };
            data.push(dataA)
        }
        setTimeout(() => {
            setExchangeLIst(data)
        }, 200);

    }
    useEffect(() => {
        fetchData();
    }, [])
    const fetch = async () => {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/marketmarker/list`)

        if (res.data) {
            var data = []
            for (let i = 0; i < res.data.result.length; i++) {
                var dataA = { value: res.data.result[i].name, label: res.data.result[i].name };
                data.push(dataA)
            }
           setListMM(data)
        }
    }
    useEffect(() => {
        fetch();
    }, [])

    return (
        <div>
            <div className='box'>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/">
                        Dashboard
                    </Link>
                    <Typography sx={{ color: 'text.primary' }}>Exchange Assignment </Typography>
                </Breadcrumbs>
            </div>
            <div className='text-inner'>
                <Card style={{ padding: '10px', paddingBottom: '60px' }}>
                    <h4>Exchange Assignment</h4>
                    <div>
                        {/* {JSON.stringify(user)} */}

                    </div>
                    <hr />
                    <Form className='admin'>
                        <FormGroup row>
                            <Label sm={2}>Exchange Name </Label>
                            <Col sm={4}>
                                <Select
                                    closeMenuOnSelect={false}
                                    components={animatedComponents}
                                    isMulti
                                    options={exchangeList}
                                    onChange={(e) => { setNewExchange((prev) => ({ ...prev, exchange_name: e })) }}
                                />
                                {/* <Input
                                    type="select" value={newExchange.exchange_name}
                                    onChange={(e) => { setNewExchange((prev) => ({ ...prev, exchange_name: e.target.value })) }}
                                >
                                    <option value=''>Select</option>
                                    {exchangeList.length > 0 &&
                                        <>
                                            {exchangeList.map((list, i) => (
                                                <option value={list.name}>{list.name}</option>
                                            ))}
                                        </>
                                    }
                                </Input> */}
                            </Col>
                            <Label sm={2}> Trading Pairs: </Label>
                            <Col sm={4}>
                                <Select
                                    closeMenuOnSelect={false}
                                    components={animatedComponents}
                                    isMulti
                                    options={TradingLIst}
                                    onChange={(e) => { setNewExchange((prev) => ({ ...prev, trading_pairs: e })) }}
                                />
                                {/* <Input type="select" value={newExchange.trading_pairs}
                                    onChange={(e) => { setNewExchange((prev) => ({ ...prev, trading_pairs: e.target.value })) }}>
                                    <option value=''>Select Trading</option>
                                    {TradingLIst.length > 0 &&
                                        <>
                                            {TradingLIst.map((list, i) => (
                                                <option value={list.name}>{list.name}</option>
                                            ))}
                                        </>
                                    }
                                </Input> */}
                            </Col>
                        </FormGroup>

                        <FormGroup row>
                            <Label sm={2}>Contract Start Date</Label>
                            <Col sm={4}>
                                <Input
                                    type="date" value={newExchange.contract_start_date}
                                    onChange={(e) => { setNewExchange((prev) => ({ ...prev, contract_start_date: e.target.value })) }}
                                />
                            </Col>

                            <Label sm={2}>Payment Due Date</Label>
                            <Col sm={4}>
                                <Input
                                    type="date" value={newExchange.payment_due_date}
                                    onChange={(e) => { setNewExchange((prev) => ({ ...prev, payment_due_date: e.target.value })) }}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label sm={2}>Payment Frequency</Label>
                            <Col sm={4}>
                                <Input type="select" value={newExchange.payment_frequency}
                                    onChange={(e) => { setNewExchange((prev) => ({ ...prev, payment_frequency: e.target.value })) }}>
                                    <option value=''>Select Frequency</option>
                                    <option value='1m'>1 Month</option>
                                    <option value='3m'>3 Months</option>
                                    <option value='6m'>6 Months</option>

                                </Input>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label sm={2}>Market Maker</Label>
                            <Col sm={4}>
                                <Select
                                    closeMenuOnSelect={false}
                                    components={animatedComponents}
                                    isMulti
                                    options={listMM}
                                    onChange={(e) => { setNewExchange((prev) => ({ ...prev, market_maker: e })) }}
                                />
                                {/* <Input type="select" value={newExchange.market_maker}
                                    onChange={(e) => { setNewExchange((prev) => ({ ...prev, market_maker: e.target.value })) }}>
                                    <option value=''>Select Market Maker</option>
                                    {listMM.length > 0 &&
                                        <>
                                            {listMM.map((list, i) => (
                                                <option key={i} value={list.id} >{list.name}</option>
                                            ))}
                                        </>
                                    }

                                </Input> */}
                            </Col>
                        </FormGroup>

                        <br />
                        <Button type='button' color='success' block onClick={SaveData}>Save</Button>
                    </Form>
                </Card>
            </div>
        </div>
    )
}

export default ExchangeAssignment