import React, { useEffect, useState } from 'react'
//import NewsList from './NewsList'
import NewsList2 from './NewsList2'
import axios from 'axios';
import { Button, Col, Row } from 'reactstrap'
import img from '../img/gold.png'
import { Link } from 'react-router-dom'
import { AiOutlineArrowRight } from "react-icons/ai";



function NewEvent() {
    const [newsList, setnewsList] = useState([]);

    const fetchLang = async () => {
        const resR = await axios.get(`${process.env.REACT_APP_API_URL}/news/list`);
        setnewsList(resR.data.result)
    }
    useEffect(() => {
        fetchLang();
    }, [])
    return (
        <div className="header-offset">
            <div className='bg-one'></div>

            <div>
                <div className='container pt-5 pb-5 new-s'>
                    <h2>News ({newsList.length} ) </h2>
                    {/* <NewsList2 /> */}
                     <Row className='pt-2 pb-2 news-a'>
                        {newsList.length > 0 &&
                            <>
                                {newsList.map((list, i) => (
                                    <Col sm={4} key={i} className="pb-5">
                                        <div>
                                            <Link to={list.hrf_link}>
                                                <img src={img} alt="" style={{ width: '100%' }} />
                                                <h4 className="pt-2">{list.subject} </h4>
                                                <p><i>{list.create_date.substring(0,10)} </i></p>
                                            </Link>
                                            <p>
                                                <div dangerouslySetInnerHTML={{ __html: list.body }} />

                                            </p>
                                            <div>
                                                <Button color="primary" href={list.hrf_link} >
                                                    Read more...
                                                    <AiOutlineArrowRight />
                                                </Button>
                                            </div>
                                        </div>
                                    </Col>
                                ))}
                            </>
                        }
                    </Row> 
                </div>
            </div>
        </div >
    )
}

export default NewEvent