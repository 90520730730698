import React from 'react';
//import ComunityForm from './ComunityForm'
import TabForCommunity from '../TabForCommunity';
import { Box } from '@mui/material';

function Community() {
  return (
    <div className="header-offset bg-aa">
      <div className="container pt-5 pb-5">
        <Box className="bg-light">
          {/* <h2 className='text-center pb-2'>Community</h2> */}
          <TabForCommunity />
          {/* <ComunityForm /> */}
        </Box>
      </div>
    </div>
  );
}

export default Community;
