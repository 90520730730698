import React, { useState, useEffect } from 'react'
import {
    Card, Breadcrumbs, Typography, Button, IconButton, ButtonGroup
} from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { FaEdit, FaTrash } from 'react-icons/fa'
import { AiOutlineFileAdd } from 'react-icons/ai';
import Swal from 'sweetalert2';


import { Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input } from 'reactstrap';

function TreasuryList() {
    const navigate = useNavigate();
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const [editData,setEditData] = useState({})

    const [usetList, setUserList] = useState([]);
    const fetchUsers = async () => {
        const listUser = await axios.get(`${process.env.REACT_APP_API_URL}/treasury/list`)
        const nnData = listUser.data.result;
        console.log(nnData)
        setUserList(nnData)
        //console.log(listUser.data.result);
    }
    const maskedNumber = async(mobileNumber) =>{
        const last4Digits = mobileNumber.slice(-2);
        const maskedNum = last4Digits.padStart(mobileNumber.length, "*");
        return maskedNum;
    }
    const clickEdit = async (row) => {
        await axios.post(`${process.env.REACT_APP_API_URL}/treasury/otp`)
        toggle()
        setEditData(row);
        //navigate('/dashboard/treasury-edit', { state: row })
    }
    const deleteRow = async (row) => {
        const listUser2 = await axios.post(`${process.env.REACT_APP_API_URL}/treasury/delete`, row)
        const nnData2 = listUser2.data.result;
        setUserList(nnData2)
    }
    const veriFy = async () => {
        Swal.fire({
            icon: "success",
            title: "Successfully verified",
            showConfirmButton: false,
            timer: 2000
        });
        setTimeout(() => {
            navigate('/dashboard/treasury-edit', { state: editData })
        }, 1000);
    }
    useEffect(() => {
        fetchUsers();
    }, [])
    return (
        <div>
            <div className='box'>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/">
                        Dashboard
                    </Link>
                    <Typography sx={{ color: 'text.primary' }}>List</Typography>
                </Breadcrumbs>
            </div>
            <div className='text-inner'>
                <Button variant="contained" color='primary'
                    onClick={() => { navigate('/dashboard/treasury-add') }}
                >Add new
                    <AiOutlineFileAdd size={20} />
                </Button>
                <Card style={{ padding: '10px' }}>
                    <h4>Treasury list</h4>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Company Name</TableCell>
                                    <TableCell >Full name</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell>Mobile</TableCell>
                                    <TableCell>Country</TableCell>
                                    <TableCell>Currency type</TableCell>
                                    <TableCell>Total Treasury Token / Coins</TableCell>
                                    <TableCell>Designation</TableCell>
                                    <TableCell>Action </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {usetList.map((row, i) => (
                                    <TableRow
                                        key={i}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell > {row.company_name}</TableCell>
                                        <TableCell >{row.f_name} {row.l_name}</TableCell>
                                        <TableCell>
                                        { row?.email.slice(0, 5)+'****'+row?.email.slice(-3)
                                            }
                                        </TableCell>
                                        <TableCell>{ row?.phone.slice(0, 5)+'****'+row?.phone.slice(-2)
                                            }</TableCell>
                                        <TableCell>{row.country}</TableCell>
                                        <TableCell>{row.currency_type}</TableCell>
                                        <TableCell>{row.total_token_coin}</TableCell>
                                        <TableCell>{row.designation}</TableCell>
                                        <TableCell >
                                            <ButtonGroup>
                                                <IconButton color='error' onClick={() => { deleteRow(row) }}>
                                                    <FaTrash />
                                                </IconButton>
                                                <IconButton color='error' onClick={() => {
                                                    clickEdit(row)
                                                }}>
                                                    <FaEdit />
                                                </IconButton>
                                            </ButtonGroup>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Modal isOpen={modal} toggle={toggle} >
                        <ModalHeader toggle={toggle} className='text-center'>Verify OTP </ModalHeader>
                        <ModalBody>
                            <form>
                                <FormGroup>
                                    <Input type="text" placeholder='Enter OTP'
                                    />
                                </FormGroup>
                                <Button variant="contained" onClick={veriFy}>verify OTP</Button>

                            </form>
                        </ModalBody>
                        <ModalFooter>
                            {/* <Button color="primary" onClick={toggle}>
                                Do Something
                            </Button>{' '}
                            <Button color="secondary" onClick={toggle}>
                                Cancel
                            </Button> */}
                        </ModalFooter>
                    </Modal>
                </Card>
            </div>
        </div>
    )
}

export default TreasuryList