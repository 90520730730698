import React, { useState, useEffect } from 'react'
import {
    Card, Breadcrumbs, Typography
} from '@mui/material'
import { Link } from 'react-router-dom'
import { Form, FormGroup, Label, Col, Input, Button } from 'reactstrap'
import { PiUserCircleGearLight } from "react-icons/pi";
import axios from 'axios';
import Swal from 'sweetalert2';
import Switch from '@mui/material/Switch';


function ExchangeManagement() {

    const [user, setUser] = useState('');
    const [pass, setPass] = useState('');
    const [Confrmpass, setConfirmPass] = useState('');


    return (
        <div>
            <div className='box'>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/">
                        Dashboard
                    </Link>
                    <Typography sx={{ color: 'text.primary' }}>Exchange Management </Typography>
                </Breadcrumbs>
            </div>
            <div className='text-inner'>
                <Card style={{ padding: '10px', paddingBottom: '60px' }}>
                    <h4>Exchange Management</h4>
                    <div>
                        {/* {JSON.stringify(user)} */}

                    </div>
                    <hr />
                    <Form>
                        <FormGroup row>
                            <Label sm={2}>Exchange Name </Label>
                            <Col sm={4}>
                                <Input
                                    placeholder="with a placeholder"
                                    type="text"
                                />
                            </Col>
                            <Label sm={2}> Trading Pairs: </Label>
                            <Col sm={4}>
                                <Input
                                    placeholder="with a placeholder"
                                    type="text"
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label sm={2}>Contract Link</Label>
                            <Col sm={4}>
                                <Input
                                    placeholder="with a placeholder"
                                    type="text"
                                />
                            </Col>
                            <Label sm={2}>Wallet Addresses</Label>
                            <Col sm={4}>
                                <Input
                                    placeholder="with a placeholder"
                                    type="text"
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label sm={2}>Contract Start Date</Label>
                            <Col sm={4}>
                                <Input
                                    placeholder="with a placeholder"
                                    type="date"
                                />
                            </Col>

                            <Label sm={2}>Exchange Status</Label>
                            <Col sm={4}>
                                <Switch />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label sm={2}>Enable/Disable Trading Pairs</Label>
                            <Col sm={4}>
                                <Switch
                                />
                            </Col>
                        </FormGroup>
                        <br />
                        <Button type='button' color='success' block>Save</Button>
                    </Form>
                </Card>
            </div>
        </div>
    )
}

export default ExchangeManagement