import React, { useState, useEffect } from 'react'
import {
    Card, Breadcrumbs, Typography,
    IconButton
} from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'
import { Form, FormGroup, Label, Col, Input, Button, Table } from 'reactstrap'
import { AiOutlineFileAdd } from 'react-icons/ai'
import axios from 'axios'

function ExchangeAssignmentList() {

    const navigate = useNavigate();
    const [list, setList] = useState([]);

    const fetchList = async () => {
        const resRelt = await axios.get(`${process.env.REACT_APP_API_URL}/exchange-assignment/list`)
        setList(resRelt.data.result)
    }
    const arrToString = (arr) => {
        var str = "";
        for (let i = 0; i < arr.length; i++) {
            str += arr[i].value;
            str += ',';
            console.log(str)
        }
        setTimeout(() => {
            return str;
        }, 1000);
    }
    useEffect(() => {
        fetchList();
    }, [])

    return (
        <div>
            <div className='box'>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/">
                        Dashboard
                    </Link>
                    <Typography sx={{ color: 'text.primary' }}>List</Typography>
                </Breadcrumbs>
            </div>
            <div className='text-inner'>
                <Button variant="contained" color='primary'
                    onClick={() => { navigate('/dashboard/exchange-assignment') }}
                >Add new
                    <AiOutlineFileAdd size={20} />
                </Button>
                <Card style={{ padding: '10px' }}>
                    <h4>Exchange Assignment list</h4>
                    <div className='table-responsive'>
                        <Table>
                            <thead>
                                <tr>
                                    <th>Sl</th>
                                    <th>Exchange Name</th>
                                    <th>Trading pairs</th>
                                    <th>Contract start date</th>
                                    <th>Payment Due Date</th>
                                    <th>Payment Frequency</th>
                                    <th>Market Maker</th>
                                </tr>
                            </thead>
                            <tbody>
                                {list.length > 0 &&
                                    <>
                                        {list.map((list, i) => (
                                            <tr key={i}>
                                                <td>{i + 1} </td>
                                                <td>
                                                    {list.exchange_name.map((item, index) => (
                                                        <div key={index}>
                                                            {item.value}
                                                        </div>
                                                    ))}
                                                </td>
                                                <td>
                                                    {list.trading_pairs.map((item, index) => (
                                                        <div key={index}>
                                                            {item.value}
                                                        </div>
                                                    ))}
                                                </td>
                                                <td>{list.contract_start_date} </td>
                                                <td>{list.payment_due_date} </td>
                                                <td>{list.payment_frequency} </td>
                                                <td>
                                                    {list.market_maker.map((item, index) => (
                                                        <div key={index}>
                                                            {item.value}
                                                        </div>
                                                    ))}
                                                </td>
                                            </tr>
                                        ))}
                                    </>
                                }

                            </tbody>
                        </Table>
                    </div>
                </Card>
            </div>
        </div>
    )
}
export default ExchangeAssignmentList